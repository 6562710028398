import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flex: "auto",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      marginTop: "25px",
    },
  },
  conatiner: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: "2rem",
  },
  innerContainer: {
    // padding: "10px px",
  },

  marginTop: {
    marginTop: 15,
  },
  marginTop20: {
    marginTop: 20,
  },
  heading: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  bold: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
  },
  button: {
    color: "white",
    borderRadius: theme.shape.borderRadius / 2,
    padding: "10px 0px",
  },
}));

export default useStyles;
