import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    // maxWidth: 200,
    // backgroundColor: "rgba(239, 166, 24,0.2)", //"#F8F8F8",
    // padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    // textAlign: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "15px 20px",
  },
  text: {
    fontSize: "1rem",
    textAlign: "center",
  },
  iconLoud: {
    width: 19.44,
    height: 20,
    marginRight: `${theme.spacing(2)}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
}));

export default useStyles;
