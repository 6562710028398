import { Dispatch } from "redux";
import { HypervisorTypes } from "../actionTypes";
import { HypervisorActions } from "../actions/hypervisor";
import { getTotalAmounts } from "../../contract/functions/hypervisor";
import { getTotalAmounts as getTotalAmountsPopsicle } from "../../contract/functions/popsicle";
import { getTotalAmounts as getTotalAmountsLixer } from "../../contract/functions/lixor";
import { getBalance } from "../../contract/functions/erc20";
import { getEthBalance } from "../../contract/getContract";
import { FINANCE_PROTOCOL } from "../../utils/enums";
import { IGetTotalAmounts } from "../../utils/generalTypes";

export const getAmounts = (
  contractAddress: string,
  platform: FINANCE_PROTOCOL
) => {
  return async (dispatch: Dispatch<HypervisorActions>) => {
    let amounts: IGetTotalAmounts;
    if (platform === FINANCE_PROTOCOL.Visor) {
      amounts = await getTotalAmounts(contractAddress);
    } else if (platform === FINANCE_PROTOCOL.Popsicle) {
      amounts = await getTotalAmountsPopsicle(contractAddress);
    } else {
      amounts = await getTotalAmountsLixer(contractAddress);
    }

    console.log("amounts", amounts);

    dispatch({
      type: HypervisorTypes.GET_AMOUNTS,
      payload: amounts,
    });
  };
};

export const setInput01 = (input: string) => {
  return (dispatch: Dispatch<HypervisorActions>) => {
    dispatch({
      type: HypervisorTypes.INPUT_01,
      payload: input,
    });
  };
};

export const setInput02 = (input: string) => {
  return (dispatch: Dispatch<HypervisorActions>) => {
    dispatch({
      type: HypervisorTypes.INPUT_02,
      payload: input,
    });
  };
};

export const setMaxAmount = (
  token0Address: string,
  token1Address: string,
  account: string
) => {
  return async (dispatch: Dispatch<HypervisorActions>) => {
    let token0: string = "0";
    let token1: string = "0";

    if (["ether"].includes(token0Address.toLowerCase())) {
      token0 = await getEthBalance(account);
    } else {
      token0 = await getBalance(token0Address, account);
    }

    if (["ether"].includes(token1Address.toLowerCase())) {
      token1 = await getEthBalance(account);
    } else {
      token1 = await getBalance(token1Address, account);
    }

    const maxAmount = {
      token0,
      token1,
    };

    dispatch({
      type: HypervisorTypes.MAX_AMOUNT,
      payload: { maxAmount },
    });
  };
};
