import { makeStyles } from "@material-ui/core";
import { ThemeOptionsX } from "../../theme/materialUiTheme";

const useStyles = makeStyles((theme: any) => ({
  dialogPaperTrans: {
    maxWidth: "410px !important",
    width: "100vw",
    borderRadius: theme.shape.borderRadius,
  },

  dialogPaper: {
    maxWidth: "400px !important",
    width: "100vw !important",
    borderRadius: theme.shape.borderRadius,
    background: "rgba(3, 10, 20,0.9)",
  },

  dialogPaperDisclaimer: {
    // maxWidth: "600px !important",
    // width: "100vw !important",
    borderRadius: theme.shape.borderRadius,
    background: "rgba(3, 10, 20,0.9)",
  },
  listItem: {
    padding: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: "pointer",
    },
  },
  listItem02: {
    // padding: "0px 1rem",
    border: `1px solid transparent`,

    "&:hover": {
      // backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
    },
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    padding: "10px 15px",
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
  },
  selected: {
    backgroundColor: theme.palette.common.hoverBC01,
  },
  listItemAvatar: {
    minWidth: 40,
  },
  input: {
    backgroundColor: theme.palette.secondary.main, //"#fafafa",
    padding: "5px 15px",
    borderRadius: theme.shape.borderRadius,
    marginTop: 15,
    fontSize: "0.8rem",
  },
  container: {
    padding: 0,
  },
  box: {
    padding: "0px 20px",
    margin: "20px 0px",
  },
  sec: {
    fontSize: "0.7rem",
    color: "#B9B9B9",
  },
  pri: {
    fontSize: "0.9rem",
    fontWeight: 500,
  },
  num: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  avatarRoot: {
    width: 25,
    height: 25,
    backgroundColor: "transparent",
  },
  MUIContainer: {
    // backgroundColor: "rgba(225,225,225,0.2)",
    // filter: "blur(10px)",
    backdropFilter: "blur(3px) !important",
    // backgroundColor: "rgba(0,0,30,0.4) !important",
    backgroundColor: "rgba(255,255,255,0.05) !important",
  },
  MUIContainerDis: {
    // backgroundColor: "rgba(225,225,225,0.2)",
    // filter: "blur(10px)",
    backdropFilter: "blur(10px) !important",
    // backgroundColor: "rgba(0,0,30,0.4) !important",
    backgroundColor: "rgba(255,255,255,0.05) !important",
  },
  marginTop: {
    marginTop: 15,
  },
  WContainer: {
    // backgroundColor: theme.palette.secondary.main,
    padding: "15px 20px",
    borderRadius: theme.shape.borderRadius,
    marginTop: 10,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    textTransform: "none",
    "&:before": {
      borderColor: theme.palette.primary.main,
    },
    // borderColor: "transparent",
  },
  wIcon: {
    width: 25,
    height: 25,
  },
  rootWContainer: {
    paddingBottom: "20px",
    // backgroundColor: "red",
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  rootContainer: {
    paddingTop: 20,
  },
  bottomSection: {
    backgroundColor: theme.palette.secondary.main,
    padding: "10px 0px",
    borderTop: "1px solid rgba(225,255,255,0.15)",
  },
  BSButton: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  createIcon: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    marginRight: "0.5rem",
  },
  check: {
    width: 120,
    height: 120,
  },
  listContainer: {
    // backgroundColor: "red",
    maxHeight: "20rem",
    overflow: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
    // "&::-webkit-scrollbar-thumb:hover": {
    //   background: "#b30000",
    // },
  },
  listContainer02: {
    // backgroundColor: "red",
    maxHeight: "20rem",
    overflow: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
    // "&::-webkit-scrollbar-thumb:hover": {
    //   background: "#b30000",
    // },
    padding: "0px 10px",
  },
  disableListItem: {
    backgroundColor: theme.palette.secondary.main,
    padding: "0px 1rem",
    "&:hover": {
      cursor: "context-menu",
    },
  },
  selectedManage: {
    // border: `1px solid transparent`,
    color: theme.palette.common.white,
    cursor: "pointer",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    padding: "10px 15px",
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
  },
  linkTrans: {
    textDecoration: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15rem",
    textTransform: "uppercase",
    fontSize: "0.8rem",
  },
  disContent: {
    // color: theme.palette.primary.main,
    margin: "20px 0px",
  },
  disButton: {
    // borderColor: theme.palette.primary.main,
    // color: theme.palette.primary.main,
    fontSize: "0.8rem",
    // margin: "20px 0px",
  },
  initialBalance: {
    // border: "1px solid red",
  },
  boxBalance: {
    padding: "5px",
    "& input": {
      width: "85%",
      padding: "10px",
      margin: "0 15px",
      outline: "none !important",
      background: "transparent",
      color: "#fff",
      borderRadius: "5px",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  years: {
    display: "flex",
    "& input": {
      maxWidth: "75%",
      padding: "10px",
      margin: "0 0 0 10px",
    },
  },
  bottom: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  pilotField: {
    border: "2px solid #4D535C",
    margin: "10px 0",
    padding: "15px 5px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& input": {
      padding: "8px",
      background: "transparent",
      outline: "none",
      border: "none",
      color: "#fff",
      fontWeight: 900,
      fontSize: "30px",
      maxWidth: "200px",
      textAlign: "right",
    },
  },
  pilotInput: {},
  dateItem: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    "& h5": {
      width: "75px",
    },
  },
  dateInput: {
    "& input": {
      maxWidth: "65px",
    },
  },
  paperWidthSm: {
    maxWidth: "400px !important",
  },
  searchBar: {
    "& .MuiIconButton-root": {
      display: "none",
    },
  },
  hidden: {
    display: "none !important",
  },
  listItemActive: {
    background: "#0F1825",
  },
  parentEmpty: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "545px !important",
    },
  },
  listItemIm: {
    padding: "10px 1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: "pointer",
    },
    marginTop: 5,
  },
  box02: {
    padding: "0px 20px",
    marginTop: "20px",
    marginBottom: 10,
  },
}));

export default useStyles;
