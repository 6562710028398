import { Dispatch } from "redux";

import { PoolTypes } from "../actionTypes";
import { PoolAction } from "../actions/poolActions";
import { Data, IAvailable, IProtocol } from "../../utils/generalTypes";

export const selectPool = (
  data: Data
): ((dispatch: Dispatch<PoolAction>) => void) => {
  return (dispatch: Dispatch<PoolAction>) => {
    dispatch({
      type: PoolTypes.SELECT_POOL,
      payload: data,
    });
  };
};

export const selectProtocol = (
  data: IProtocol
): ((dispatch: Dispatch<PoolAction>) => void) => {
  return (dispatch: Dispatch<PoolAction>) => {
    dispatch({
      type: PoolTypes.SELECT_PROTOCOL,
      payload: data,
    });
  };
};

export const selectPlatform = (
  platform: IAvailable | undefined
): ((dispatch: Dispatch<PoolAction>) => void) => {
  return (dispatch: Dispatch<PoolAction>) => {
    dispatch({
      type: PoolTypes.SELECT_PLATFORM,
      payload: platform,
    });
  };
};
