import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 25,
    height: 25,
  },
  amount: {
    fontSize: "1.2rem",
    color: "white",
    fontWeight: 500,
  },
  iconContainer01: {
    backgroundColor: "rgba(207, 15, 109,0.2)",
    borderRadius: theme.shape.borderRadius,
    padding: 3,
  },
  iconContainer02: {
    backgroundColor: "rgba(255,255,255,0.2)",
    position: "absolute",
    top: 0,
    left: -20,
    padding: 3,
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
