import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  feetier: {
    marginLeft: "10px",
  },
}));

export default useStyles;
