const DEV_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";

const logMessage = (title: string, ...params: any): void => {
  if (DEV_ENVIRONMENT) {
    console.log(title, " -> ", ...params, " -> ", Date.now());
  }
};

const logError = (title: string, ...params: any): void => {
  if (DEV_ENVIRONMENT) {
    console.error("ERROR ", title, " -> ", ...params, " -> ", Date.now());
  }
};

export { logMessage, logError };
