import { useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { ButtonDropIcon, TokenLogo } from "../";
import useStylesSelectToken from "./styles";
import { IToken } from "../../utils/generalTypes";
import { truncSymbol } from "../../utils/formating";
//import { purplishButton } from '../../theme/materialUiTheme';

interface ISelectToken {
  selected: boolean;
  viewOnly?: boolean;
  rounded?: boolean;
  logoName: string;
  logoUrl: string;
  check: boolean;
}

const SelectToken: React.FC<ISelectToken> = ({
  selected = false,
  logoName = "",
  logoUrl = "",
  viewOnly = false,
  rounded = false,
  check = true,
}) => {
  const classes = useStylesSelectToken();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));

  const isSelected = !selected
    ? classes.containerUnselected
    : classes.containerSelected;

  const isRounded = rounded ? classes.roundedViewOnly : classes.viewOnly;

  const isViewOnly = viewOnly ? isRounded : "";

  return (
    <>
      <Box className={[isSelected, isViewOnly].join(" ")}>
        {selected ? (
          <Box
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <TokenLogo uri={logoUrl ?? ""} />
            <Typography className={classes.typoPri}>
              {truncSymbol(logoName, xs_ ? 4 : 8)}
            </Typography>
          </Box>
        ) : (
          <Typography
            className={classes.typoPri}
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
            }}
          >
            Select
          </Typography>
        )}
      </Box>
      {/* </ThemeProvider> */}
    </>
  );
};

export default SelectToken;
