import { Box, useTheme, useMediaQuery } from "@material-ui/core";

import useStyles from "./styles";
import TokenLogo from "./index";

interface IDoubleToken {
  token0Uri: string | undefined;
  token1Uri: string | undefined;
  className?: string;
}

const DoubleToken: React.FC<IDoubleToken> = ({
  token0Uri,
  token1Uri,
  className,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      style={{
        position: "relative",
        marginRight: xs_ ? 20 : 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={`${classes.doubleToken} ${className ? className : ""}`}
    >
      <TokenLogo uri={token0Uri} />
      <Box style={{ position: "absolute", top: 0, right: -18 }}>
        <TokenLogo uri={token1Uri} />
      </Box>
    </Box>
  );
};

export default DoubleToken;
