import { MaxUint256 } from "@ethersproject/constants";
import Web3 from "web3";

import { erc20TokenContract, toEth } from "../getContract";
import { logError } from "../../utils/logs";
import { CONTRACT_ADDRESSES } from "../../config/Constants";
import {
  transactionPending,
  transactionSuccess,
  transactionFailed,
  transactionRejected,
} from "../../redux";
import { formatAmount, parseAmount } from "../../utils/formating";

export const getDecimals = async (tokenAddress: string): Promise<number> => {
  try {
    const contract = erc20TokenContract(tokenAddress);
    const decimals = await contract?.methods.decimals().call();
    return Number(decimals) ?? 0;
  } catch (e) {
    logError("getDecimals", e);
    return 0;
  }
};

export const getName = async (tokenAddress: string): Promise<string> => {
  try {
    const contract = erc20TokenContract(tokenAddress);
    const name = await contract?.methods.name().call();
    return name ?? "";
  } catch (e) {
    logError("getName", e);
    return "";
  }
};

export const getSymbol = async (tokenAddress: string): Promise<string> => {
  try {
    const contract = erc20TokenContract(tokenAddress);
    const symbol = await contract?.methods.symbol().call();
    return symbol ?? "";
  } catch (e) {
    logError("getSymbol", e);
    return "";
  }
};

export const getBalance = async (
  tokenAddress: string,
  walletAddress: string
): Promise<string> => {
  try {
    const contract = erc20TokenContract(tokenAddress);
    const decimals = await getDecimals(tokenAddress);
    const balance = await contract?.methods.balanceOf(walletAddress).call();
    // console.log(decimals, balance, toMWei(balance ?? "0"));

    // if (decimals.toString() == "6") return toMWei(balance ?? "0");

    return formatAmount(balance ?? "", decimals);
  } catch (e) {
    logError("getBalance", e);
    return "";
  }
};

export const toWei = async (amount: string, tokenAddress: string) => {
  try {
    if (tokenAddress === "ether") return Web3.utils.toWei(amount, "ether");

    const decimals = await getDecimals(tokenAddress);

    const amountWei = parseAmount(amount, decimals);
    console.log(decimals, amountWei);

    return amountWei;
  } catch (e) {
    logError("to wei", e);
    return "0";
  }
};

export const getAllowance = async (
  tokenAddress: string,
  walletAddress: string,
  spenderAddress: string
): Promise<number> => {
  try {
    const contract = erc20TokenContract(tokenAddress);
    const allowance = await contract?.methods
      .allowance(walletAddress, spenderAddress)
      .call();
    return Number(allowance) ?? 0;
  } catch (e) {
    logError("allowance", e);
    return 0;
  }
};

export const approve = async (
  tokenAddress: string,
  walletAddress: string,
  spender: string,
  callback: any = () => {}
): Promise<void> => {
  try {
    transactionPending("", { text: "Approval Pending" }, "Approving");
    const contract = erc20TokenContract(tokenAddress);
    await contract?.methods
      .approve(spender, MaxUint256._hex)
      .send({ from: walletAddress })
      .on("transactionHash", (txnHash: string) => {
        transactionPending(
          txnHash,
          { text: "Approval Pending", link: txnHash },
          "Approving"
        );
      })
      .then((receipt: any) => {
        transactionSuccess(receipt.transactionHash, {
          text: "Approval Successful",
          link: receipt.transactionHash,
        });
        callback();
      })
      .catch((e: any) => {
        if (e.code === 4001) {
          transactionRejected({ text: "Approval Rejected" });
        } else {
          transactionFailed({ text: "Approval Failed" });
        }
        logError("approve", e);
      });
  } catch (e) {
    logError("approve", e);
    transactionFailed({ text: "Approval Failed" });
  }
};
