import {
  Container,
  Typography,
  DialogContent,
  CircularProgress,
  Grid,
  Link,
  Box,
} from '@material-ui/core';
import { LinkOutlined } from '@material-ui/icons';

import useStyles from './styles';
import { IMDialog, Check, Remove, Block, CloseButton } from '../';
import { useModal } from '../../hooks';
import { ETHERSCAN_TX_BASE_URL } from '../../config/Constants';

interface ITDialog {
  open: boolean;
  variant: string;
  link?: string;
}

export const TDialog: React.FC<ITDialog> = ({ open, variant, link }) => {
  const classes = useStyles();
  const { hideModalF } = useModal();

  const link_JSX = (
    <Link className={classes.linkTrans} target='_blank' href={ETHERSCAN_TX_BASE_URL + link}>
      <LinkOutlined style={{ marginRight: 10 }} /> View on Etherscan
    </Link>
  );

  return (
    <IMDialog PaperProps={{ className: classes.dialogPaperTrans }} open={open} close={hideModalF}>
      <DialogContent
        style={{
          padding: 10,
        }}
      >
        <Container disableGutters maxWidth='xs'>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            spacing={2}
            style={{
              minHeight: '300px',
              height: '400px',
              borderRadius: 10,
            }}
          >
            <Box style={{ position: 'absolute', right: '5%', top: '5%' }}>
              <CloseButton onClick={hideModalF} />
            </Box>

            {variant === 'loading' && (
              <>
                <Grid item>
                  <CircularProgress size={150} thickness={0.5} color='primary' />
                </Grid>
                <Grid item>
                  <Typography style={{ textAlign: 'center' }}>
                    Please wait for confirmation
                  </Typography>
                </Grid>
                {link && link_JSX}
              </>
            )}

            {variant === 'success' && ( //success
              <>
                <Grid item>
                  <Check className={classes.check} />
                </Grid>
                <Grid item>
                  <Typography>Your Transaction has been Confirmed</Typography>
                </Grid>
                {link && link_JSX}
              </>
            )}
            {variant === 'error' && (
              <>
                <Grid item>
                  <Remove className={classes.check} />
                </Grid>
                <Grid item>
                  <Typography>Your Transaction has been failed</Typography>
                </Grid>
              </>
            )}

            {variant === 'blocked' && (
              <>
                <Grid item>
                  <Block className={classes.check} />
                </Grid>
                <Grid item>
                  <Typography>Your Transaction has been rejected</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </DialogContent>
    </IMDialog>
  );
};
