import {
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MainHeader } from "../components";
import { Main } from "../pages";

const redirectRoute = "/";

function Routes() {
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(false);
  const { account, chainId } = useWeb3React();

  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      if (localStorage.getItem("connected") === "true") return setAuth(true);

      setAuth(false);
      setLoading(false);
    }, 500);
  }, [account]);

  useEffect(() => {
    localStorage.setItem("connected", account ? "true" : "false");
    setAuth(Boolean(account));
  }, [account]);

  if (loading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100vw"
        height="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    );
  return (
    <>
      <BrowserRouter>
        <MainHeader bottom={sm_ ? true : false} />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/lixor" component={Main} />
          <Redirect to={redirectRoute} />
        </Switch>
        {/* <MainFooter /> */}
      </BrowserRouter>
    </>
  );
}

export default Routes;
