import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import mainBg from "../../src/assets/others/mainBg.png";

// "#F8F8F8" light gray

export interface CommonColors {
  black: string;
  white: string;
  hoverBC01?: string;
  hoverTC01?: string;
  darkBlackish?: string;
}

interface PaletteOptionsX extends PaletteOptions {
  common?: Partial<CommonColors>;
}

export interface ThemeOptionsX extends ThemeOptions {
  palette?: PaletteOptionsX;
}

export const themeDark = createMuiTheme({
  mixins: {
    toolbar: {
      // minHeight: 70,
    },
  },
  breakpoints: {
    values: {
      xs: 500,
      sm: 650,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: "dark",
    common: {
      black: "rgba(0, 0, 0, 0.6)",
      hoverBC01: "#0F1825",
      hoverTC01: "#FFFFFF",
      blackish: "#080F19",
    },
    primary: {
      main: "#EFA618",
    },
    secondary: {
      main: "#0F1825",
    },

    background: {
      paper: "#080F19",
      default: "#132337", //"#05070A",
    },
  },

  typography: {
    fontFamily: "Rubik",
    caption: {
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "none",
    },
    body2: {
      fontSize: "0.9rem",
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          background: `url(${mainBg}) no-repeat`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
} as ThemeOptionsX);

export const themeLight = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#EFA618",
    },
    secondary: {
      main: "#F8F8F8",
    },
    background: {
      default: "#F8F8F8",
    },
  },

  typography: {
    fontFamily: "Rubik",
    caption: {
      textTransform: "none",
      fontSize: "0.65rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "none",
    },
    body2: {
      fontSize: "0.9rem",
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
});

// export const lightTheme = createMuiTheme({
//   palette: {
//     type: "light",
//     primary: {
//       main: "#65C988",
//     },
//     error: {
//       main: "#c66065",
//     },
//     background: {
//       main: "#F5F5F5",
//       primary: "#fff",
//       secondary: "#F5F5F5",
//       input: "#F5F5F5",
//       dialog: "#fff",
//       secondary2: "#fff",
//       disabled: "#E2E2E2",
//       hover: "#E5E5E5",
//       overlay: "rgba(0,0,0,0.6)",
//     },
//     text: {
//       primary: "#000",
//       secondary: "#666",
//       disabled: "#B2B2B2",
//       placeholder: "#B8B8B8",
//       title: "#787A97",
//     },
//     border: {
//       lightBorder: "#E7E8EA",
//     },
//   },
//   typography: {
//     fontFamily: "Rubik",
//   },
// });
