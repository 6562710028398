import { useMemo } from "react";
import { GridJustification, Typography } from "@material-ui/core";

import useStyles from "./styles";
import { RowAlignCenter, VisorLogo, LixorLogo, PopsicleLogo } from "../";
import { FINANCE_PROTOCOL } from "../../utils/enums";

interface IMigrateLiquidityTag {
  protocol: FINANCE_PROTOCOL | string;
  justify?: GridJustification;
  hideLable?: boolean;
}

const MigrateLiquidityTag: React.FC<IMigrateLiquidityTag> = ({
  protocol,
  justify,
  hideLable = false,
}) => {
  const classes = useStyles();

  const protocolName = useMemo<string>(() => {
    if (protocol === FINANCE_PROTOCOL.Visor) return "Visor finance";
    else if (protocol === FINANCE_PROTOCOL.Lixor) return "Lixir finance";
    else if (protocol === FINANCE_PROTOCOL.Popsicle) return "Popsicle finance";
    else return "";
  }, [protocol]);

  return (
    <RowAlignCenter
      justify={justify}
      element01ClassName={classes.imgWrapper}
      elements01={
        protocol === FINANCE_PROTOCOL.Visor ? (
          <VisorLogo className={classes.img} />
        ) : protocol === FINANCE_PROTOCOL.Lixor ? (
          <LixorLogo className={classes.img02} />
        ) : (
          <PopsicleLogo className={classes.img02} />
        )
      }
      elements02={
        !hideLable ? (
          <Typography variant="body2" style={{ textTransform: "capitalize" }}>
            {protocolName}
          </Typography>
        ) : null
      }
    ></RowAlignCenter>
  );
};

export default MigrateLiquidityTag;
