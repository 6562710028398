import { useEffect, useState } from "react";
import { Input, InputAdornment, Typography } from "@material-ui/core";

import useStylesInputMax from "./styles";

import { toFixed } from "../../utils/formating";
// import { validAmount } from "../../utils/helpers";

interface IInputMaxProps {
  style?: React.CSSProperties;
  className?: string;
  setInput: any;
  value: string;
  onClickMax: () => void;
  maxAmount: string;
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const InputMax: React.FC<IInputMaxProps> = ({
  style,
  className,
  setInput,
  value,
  onClickMax,
  maxAmount,
}) => {
  const classes = useStylesInputMax();
  const [amount, setAmount] = useState<any>(0);

  //   const calculateRatio = useLiquidityRatio();
  //   const { checkAllowance } = useLiquidityAllowance();

  //   const calculateRespectiveValue = (
  //     type: LIQUIDITY_TOKENS,
  //     value: string,
  //     decimals: number,
  //     returnDecimals: number
  //   ): string => {
  //     if (
  //       checkAllowance(
  //         type === LIQUIDITY_TOKENS.TOKEN_0
  //           ? LIQUIDITY_TOKENS.TOKEN_1
  //           : LIQUIDITY_TOKENS.TOKEN_0
  //       ) &&
  //       validateAmount(value, decimals)
  //     ) {
  //       const returnedAmount = calculateRatio(type)
  //         .multipliedBy(value)
  //         .toPrecision(8);
  //       return validateAmount(returnedAmount, returnDecimals)
  //         ? returnedAmount
  //         : "0";
  //     } else return "";
  //   };

  const validateAmount = (amount: string, decimals: number) => {
    const _amount = toFixed(amount, decimals);
    return parseFloat(_amount) > 0 ? true : false;
  };

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    // console.log("this is handle change value: ", value);
    if (/^\d*(\.\d*)?$/.test(value)) setInput(value);
  };

  //   const handleMax = (): void => {
  //     if (
  //       token0 &&
  //       token1 &&
  //       ((amount0Reserves && amount1Reserves) ||
  //         (initialAmount && sqrtPrice) ||
  //         tick)
  //     ) {
  //       if (type === LIQUIDITY_TOKENS.TOKEN_0) {
  //         const _amount0 = new BigNumber(
  //           formatAmount(token0?.balance ?? "", token0.decimals)
  //         );
  //         addDataF({
  //           amount0:
  //             token0.isETH && _amount0.gt(ETH_MIN_LIMIT)
  //               ? _amount0.minus(ETH_MIN_LIMIT).toString()
  //               : _amount0.toString(),
  //           amount1: calculateRespectiveValue(
  //             LIQUIDITY_TOKENS.TOKEN_0,
  //             _amount0.toString(),
  //             token0.decimals,
  //             token1.decimals
  //           ),
  //         });
  //       } else {
  //         const _amount1 = new BigNumber(
  //           formatAmount(token1?.balance ?? "", token1.decimals)
  //         );
  //         addDataF({
  //           amount0: calculateRespectiveValue(
  //             LIQUIDITY_TOKENS.TOKEN_1,
  //             _amount1.toString(),
  //             token1.decimals,
  //             token0.decimals
  //           ),
  //           amount1:
  //             token1.isETH && _amount1.gt(ETH_MIN_LIMIT)
  //               ? _amount1.minus(ETH_MIN_LIMIT).toString()
  //               : _amount1.toString(),
  //         });
  //       }
  //     }
  //   };

  //   const checkAmount = (): boolean | undefined => {
  //     if (token0 && token1 && (amount0 || amount1)) {
  //       if (type === LIQUIDITY_TOKENS.TOKEN_0) {
  //         return validAmount(token0, amount0);
  //       } else {
  //         return validAmount(token1, amount1);
  //       }
  //     }
  //   };

  const err = parseFloat(value) > parseFloat(maxAmount) ? classes.error : "";

  return (
    <Input
      style={style}
      fullWidth
      className={`${className ?? classes.container} ${err}`}
      disableUnderline
      id="standard-adornment-amount"
      placeholder="0.0"
      onChange={handleChange}
      autoComplete="off"
      value={value}
      endAdornment={
        <InputAdornment onClick={onClickMax} position="end">
          <Typography className={classes.maxButton} color="primary">
            Max
          </Typography>
        </InputAdornment>
      }
    />
  );
};

export default InputMax;
