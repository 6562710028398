import testData from "../../components/dialog/dialogData";
import { PoolAction } from "../actions/poolActions";
import { PoolTypes } from "../actionTypes";
import { Data, IAvailable, IProtocol } from "../../utils/generalTypes";

export interface PoolState {
  allPools: Data[];
  selectedPool: Data | null;
  selectedProtocol: IProtocol | null;
  selectedPlatform: IAvailable | undefined;
}

const initialState: PoolState = {
  allPools: testData,
  selectedPool: null,
  selectedProtocol: null,
  selectedPlatform: undefined,
};

const addPoolReducer = (
  state: PoolState = initialState,
  action: PoolAction
) => {
  switch (action.type) {
    case PoolTypes.SELECT_POOL:
      return {
        ...state,
        selectedPool: action.payload,
      };
    case PoolTypes.SELECT_PROTOCOL:
      return {
        ...state,
        selectedProtocol: action.payload,
      };

    case PoolTypes.SELECT_PLATFORM:
      return {
        ...state,
        selectedPlatform: action.payload,
      };
    default:
      return state;
  }
};

export default addPoolReducer;
