// import { img } from "@material-ui/core";
import React from "react";

import useStyles from "./styles";

import eth from "../../assets/tokens/eth.png";
import uni from "../../assets/tokens/uniswap.png";
import loud from "../../assets/others/loud.png";
import wallet from "../../assets/others/wallet.png";
import design from "../../assets/others/design.png";
import programmer from "../../assets/others/programmer.png";
import metaMask from "../../assets/wallets/metamask.png";
import coinbase from "../../assets/wallets/coinbase.png";
import fortmatic from "../../assets/wallets/fortmatic.png";
import injected from "../../assets/wallets/injected.png";
import portis from "../../assets/wallets/portis.png";
import walletconnect from "../../assets/wallets/walletconnect.png";
import check from "../../assets/others/check.png";
import remove from "../../assets/others/remove.png";
import block from "../../assets/others/block.png";
import logo from "../../assets/others/logo.png";
import createdBy from "../../assets/others/createdBy.svg";
import logoTick from "../../assets/others/logo-tick.svg";
import uniswapLogo from "../../assets/others/uniswap.png";
import sushiswapLogo from "../../assets/others/sushiswap.png";
import Visor from "../../assets/others/visor.png";
import Lixir from "../../assets/svgs/lixir.svg";
import Popsicle from "../../assets/others/ice.png";

interface Image {
  className?: any;
}

interface CreatedBy extends Image {
  style?: any;
}

export const CreatedBy: React.FC<CreatedBy> = ({ className, style }) => {
  const classes = useStyles();
  return (
    <img style={style} className={className} src={createdBy} />
    // <img className={{ root: classes.eth }} className={className} src={eth} />
  );
};

export const Eth: React.FC<Image> = ({ className }) => {
  const classes = useStyles();
  return (
    <img className={className ?? classes.eth} src={eth} />
    // <img className={{ root: classes.eth }} className={className} src={eth} />
  );
};

export const Uni: React.FC<Image> = ({ className }) => {
  const classes = useStyles();
  return <img className={className ?? classes.eth} src={uni} />;
};

export const Loud: React.FC<Image> = ({ className }) => {
  return <img className={className} src={loud} />;
};

export const Wallet: React.FC<Image> = ({ className }) => {
  return <img className={className} src={wallet} />;
};

export const Design: React.FC<Image> = ({ className }) => {
  return <img className={className} src={design} />;
};

export const Programmer: React.FC<Image> = ({ className }) => {
  return <img className={className} src={programmer} />;
};

export const MetaMask: React.FC<Image> = ({ className }) => {
  return <img className={className} src={metaMask} />;
};

export const CoinBase: React.FC<Image> = ({ className }) => {
  return <img className={className} src={coinbase} />;
};

export const FortMatic: React.FC<Image> = ({ className }) => {
  return <img className={className} src={fortmatic} />;
};

export const Injected: React.FC<Image> = ({ className }) => {
  return <img className={className} src={injected} />;
};

export const Portis: React.FC<Image> = ({ className }) => {
  return <img className={className} src={portis} />;
};

export const WalletConnect: React.FC<Image> = ({ className }) => {
  return <img className={className} src={walletconnect} />;
};

export const Check: React.FC<Image> = ({ className }) => {
  return <img className={className} src={check} />;
};

export const Remove: React.FC<Image> = ({ className }) => {
  return <img className={className} src={remove} />;
};

export const Block: React.FC<Image> = ({ className }) => {
  return <img className={className} src={block} />;
};

export const Logo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={logo} />;
};

export const LogoTick: React.FC<Image> = ({ className }) => {
  return <img className={className} src={logoTick} />;
};

export const UniswapLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={uniswapLogo} alt="uniswapLogo" />;
};

export const SushiswapLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={sushiswapLogo} alt="sushiswapLogo" />;
};

export const VisorLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={Visor} alt="voisor logo" />;
};

export const LixorLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={Lixir} alt="lixir logo" />;
};

export const PopsicleLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={Popsicle} alt="ice logo" />;
};
