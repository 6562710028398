import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import useStylesButtonIndicator from "./styles";

interface IButtonIndicator {
  onClick: (value: any) => any;
  size: number;
  label: string;
  disabled: boolean;
  children: JSX.Element;
  containerStyle: any;
  onlyDisable: boolean;
  progressStyle: any;
  type?: "over" | "left";
}

const ButtonIndicator: React.FC<IButtonIndicator | any> = ({
  onClick = () => {},
  size = 16,
  label = "label",
  disabled: loading,
  children,
  containerStyle,
  onlyDisable = false,
  progressStyle,
  type = "left",
  ...rest
}) => {
  const classes = useStylesButtonIndicator();
  return (
    <div style={containerStyle} className={classes.wrapper}>
      <Button
        // color="primary"
        // variant="contained"
        {...rest}
        onClick={onClick}
        disabled={loading || onlyDisable}
      >
        {loading && type === "left" && (
          <CircularProgress
            style={progressStyle}
            size={size}
            className={classes.buttonProgress2nd}
          />
        )}{" "}
        {children ? children : label}
      </Button>
      {loading && type === "over" && (
        <CircularProgress
          style={progressStyle}
          size={size}
          className={classes.buttonProgress}
        />
      )}
    </div>
  );
};

export default ButtonIndicator;
