import { PopsicleContract } from "../getContract";
import { logError } from "../../utils/logs";
import {
  transactionFailed,
  transactionPending,
  transactionRejected,
  transactionSuccess,
} from "../../redux";
import { TRANSACTION_STATUS } from "../../utils/enums";
import {
  IDepositFunc,
  IGetTotalAmountsFunc,
  TransactionReceipt,
} from "../../utils/generalTypes";
import { getDecimals } from "./erc20";
import { formatAmount } from "../../utils/formating";

export const getTotalAmounts: IGetTotalAmountsFunc = async (
  contractAddress: string
) => {
  try {
    const contract = PopsicleContract(contractAddress);

    let amounts = await contract?.methods.usersAmounts().call();
    let token0 = await contract?.methods.token0().call();
    let token1 = await contract?.methods.token1().call();

    let d0 = await getDecimals(token0 ?? "");
    let d1 = await getDecimals(token1 ?? "");

    console.log("amounts from contract", amounts);

    return {
      amount0: formatAmount(amounts?.amount0 ?? "", d0),
      amount1: formatAmount(amounts?.amount1 ?? "", d1),
    };
  } catch (e) {
    logError("deposit", e);
    return {
      amount0: undefined,
      amount1: undefined,
    };
  }
};

export const deposit: IDepositFunc = async ({
  amount0,
  amount1,
  account,
  contractAddress,
  args,
}) => {
  try {
    const { walletAddress, txnMessage, logMessage, dappLoading, callback } =
      args;

    let transaction = {
      hash: "",
      account: "",
      message: "",
      status: TRANSACTION_STATUS.PENDING,
      checkCount: 0,
    };

    // console.log(amount0, amount1, account);
    const contract = PopsicleContract(contractAddress);

    await contract?.methods
      .deposit(amount0, amount1, account)
      .send({
        from: account,
      })
      .on("transactionHash", (txnHash: string) => {
        transaction = {
          hash: txnHash,
          account: walletAddress,
          message: txnMessage,
          status: TRANSACTION_STATUS.PENDING,
          checkCount: 0,
        };
        transactionPending(
          txnHash,
          { link: txnHash, text: "text", secondaryText: "sec" },
          dappLoading
        );
      })
      .then((receipt: TransactionReceipt) => {
        transaction["status"] = TRANSACTION_STATUS.SUCCESS;
        transactionSuccess(receipt.transactionHash, {
          link: receipt.transactionHash,
          text: "text",
          secondaryText: "sec",
        });
        callback();
      })
      .catch((e: any) => {
        if (e.code === 4001) {
          transactionRejected({
            link: transaction.hash,
            text: "text",
            secondaryText: "sec",
          });
        } else {
          transaction["status"] = TRANSACTION_STATUS.FAILED;

          transactionFailed({
            link: transaction.hash,
            text: "text",
            secondaryText: "sec",
          });
        }
        logError(`${logMessage}-e`, e);
      });
  } catch (e) {
    logError("deposit", e);
  }
};
