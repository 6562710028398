import { UiTypes } from "../actionTypes";
import { Dispatch } from "redux";
import { UiAction } from "../actions/uiActions";
import { store } from "../";
import { IModal, ISnackbar } from "../../utils/generalTypes";

export const hideModal = () => {
  return (dispatch: Dispatch<UiAction>) => {
    dispatch({
      type: UiTypes.HIDE_MODAL,
    });
  };
};

export const showSnackbar = (severity: string, message: string) => {
  return (dispatch: Dispatch<UiAction>) => {
    dispatch({
      type: UiTypes.SHOW_SNACKBAR,
      payload: { severity, message },
    });
  };
};

export const hideSnackbar = () => {
  return (dispatch: Dispatch<UiAction>) => {
    dispatch({ type: UiTypes.HIDE_SNACKBAR });
  };
};

export const showSnackbarInDeep = (data: ISnackbar) => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: data,
  });
};

export const setApprovalState = (data: boolean) => {
  store.dispatch({
    type: UiTypes.SET_APPROVAL_STATE,
    payload: data,
  });
};

export const transactionPending = (
  txHash: string,
  modal: IModal,
  loading: string
) => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: "Transaction Pending", severity: "info", txHash },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: "loading", ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: loading,
  });
};

export const transactionSuccess = (txHash: string, modal: IModal) => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: "Transaction Successful", severity: "success", txHash },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: "success", ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: "",
  });
};

export const transactionRejected = (modal: IModal) => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: "Transaction Rejected", severity: "error", txHash: "" },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: "blocked", ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: "",
  });
};

export const transactionFailed = (modal: IModal) => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: "Transaction Failed", severity: "error", txHash: "" },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: "error", ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: "",
  });
};
