export enum UiTypes {
  SHOW_SNACKBAR = "SHOW_SNACKBAR",
  HIDE_SNACKBAR = "HIDE_SNACKBAR",
  SHOW_SNACKBAR_INDEEP = "SHOW_SNACKBAR_INDEEP",
  SHOW_MODAL = "SHOW_MODAL",
  HIDE_MODAL = "HIDE_MODAL",
  SET_DAPP_LOADING = "SET_DAPP_LOADING",
  SET_APPROVAL_STATE = "SET_APPROVAL_STATE",
}

export enum PoolTypes {
  SELECT_POOL = "SELECT_POOL",
  SELECT_PROTOCOL = "SELECT_PROTOCOL",
  SELECT_PLATFORM = "SELECT_PLATFORM",
}

export enum HypervisorTypes {
  GET_AMOUNTS = "GET-AMOUNTS",
  INPUT_01 = "input-01",
  INPUT_02 = "input-02",
  MAX_AMOUNT = "maxAmount",

  // SELECT_PROTOCOL = "SELECT_PROTOCOL",
}
