import Web3 from "web3";

import erc20Abi from "./abis/ERC20.json";
import NFPAbi from "./abis/NonfungiblePositionManager.json";
import poolAbi from "./abis/poolAbi.json";
import multiCallAbi from "./abis/MulticallAbi.json";
import { logError } from "../utils/logs";
import { CONTRACT_ADDRESSES, CHAIN_ID } from "../config/Constants";
import { ERC20 } from "./types/ERC20";
import { NonfungiblePositionManager } from "./types/NonfungiblePositionManager";
import { PoolAbi } from "./types/poolAbi";
import { MulticallAbi } from "./types/MulticallAbi";

import HypervisorABI from "./abis/Hypervisor.json";
import LixorABI from "./abis/Lixor.json";
import PopsicleAbi from "./abis/Popsicle.json";

import { Hypervisor } from "./types/Hypervisor";
import { Lixor } from "./types/Lixor";
import { Popsicle } from "./types/Popsicle";

let web3: any;
let web3Infura: any;

const infuraUrl =
  CHAIN_ID === 1
    ? `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
    : `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;

try {
  web3 = new Web3(window?.web3?.currentProvider);
  web3Infura = new Web3(infuraUrl);
} catch (e) {
  logError("Connect Web3", e);
}

export const setWeb3Provider = (provider: any) => {
  web3 = new Web3(provider);
};

export const erc20TokenContract = (tokenAddress: string) => {
  let contract: ERC20;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(erc20Abi, tokenAddress);
    } else {
      contract = new web3Infura.eth.Contract(erc20Abi, tokenAddress);
    }
    return contract;
  } catch (e) {
    logError("erc20TokenContract", e);
  }
};

export const poolContract = (tokenAddress: string) => {
  let contract: PoolAbi;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(poolAbi, tokenAddress);
    } else {
      contract = new web3Infura.eth.Contract(poolAbi, tokenAddress);
    }
    return contract;
  } catch (e) {
    logError("poolabi", e);
  }
};

export const NFPContract = (tokenAddress: string) => {
  let contract: NonfungiblePositionManager;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(NFPAbi, tokenAddress);
    } else {
      contract = new web3Infura.eth.Contract(NFPAbi, tokenAddress);
    }
    return contract;
  } catch (e) {
    logError("nfp", e);
  }
};

export const HypervisorContract = (tokenAddress: string) => {
  let contract: Hypervisor;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(HypervisorABI, tokenAddress);
    } else {
      contract = new web3Infura.eth.Contract(HypervisorABI, tokenAddress);
    }
    return contract;
  } catch (e) {
    logError("nfp", e);
  }
};

export const LixorContract = (contractAddress: string) => {
  let contract: Lixor;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(LixorABI, contractAddress);
    } else {
      contract = new web3Infura.eth.Contract(LixorABI, contractAddress);
    }
    return contract;
  } catch (e) {
    logError("lixor", e);
  }
};

export const PopsicleContract = (contractAddress: string) => {
  let contract: Popsicle;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(PopsicleAbi, contractAddress);
    } else {
      contract = new web3Infura.eth.Contract(PopsicleAbi, contractAddress);
    }
    return contract;
  } catch (e) {
    logError("popsicle", e);
  }
};

export const MuticallContract = (tokenAddress: string) => {
  let contract: MulticallAbi;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(multiCallAbi, tokenAddress);
    } else {
      contract = new web3Infura.eth.Contract(multiCallAbi, tokenAddress);
    }
    return contract;
  } catch (e) {
    logError("muticall", e);
  }
};

export const getWeb3Provider = () => {
  if (window?.web3?.currentProvider || web3) return web3;
  else return web3Infura;
};

export const getEthBalance = async (account: string) => {
  try {
    const balance = await web3.eth.getBalance(account);
    return toEth(balance);
  } catch (e) {
    logError("getEthBalance", e);
    return "0";
  }
};

export const toEth = (amount: string) => {
  try {
    const amountEth = Web3.utils.fromWei(amount, "ether");
    return amountEth;
  } catch (e) {
    logError("toEth", e);
    return "0";
  }
};

// export const toMWei = (amount: string) => {
//   try {
//     const amountEth = Web3.utils.fromWei(amount, "mwei");
//     return amountEth;
//   } catch (e) {
//     logError("toSzabo", e);
//     return "0";
//   }
// };
