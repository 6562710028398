import axios from "axios";
import { Dispatch } from "redux";
import { ITokenList, IToken } from "../../utils/generalTypes";
import { UN_KNOWN_LOGO } from "../../constants";
import { logError } from "../../utils/logs";

//get token logo
export const getTokenLogo = async (uri: string): Promise<string> => {
  let _uri = uri;
  try {
    if (uri.startsWith("ipfs")) {
      const _val = uri?.split("//");
      _uri = "https://ipfs.io/ipfs/" + _val[1];
    }
    const res = await axios.get(_uri);
    if (res) {
      return _uri;
    } else {
      return UN_KNOWN_LOGO;
    }
  } catch (error) {
    logError("Token Logo", error);
    return UN_KNOWN_LOGO;
  }
};
