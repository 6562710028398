import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "15rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    margin: "0",
    height: "25px",
    width: "25px",
    marginRight: "5px",
    // border: "1px solid transparent",
    borderRadius: "15px",
  },
  img02: {
    margin: "0",
    height: "25px",
    width: "25px",
    marginRight: "5px",
    // border: "1px solid transparent",
    // borderRadius: "15px",
  },
  imgWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
