import { combineReducers } from "redux";

import uiReducer from "./uiReducer";
import poolReducer from "./poolReducer";
import hypervisor from "./hypervisor";

export const rootReducer = combineReducers({
  ui: uiReducer,
  pools: poolReducer,
  hypervisor: hypervisor,
});

export default rootReducer;

export type State = ReturnType<typeof rootReducer>;
