import { v4 as uuidv4 } from "uuid";
export interface Data {
  id: string;
  name: string;
  percent: string;
  address: string;
  logo1: string;
  logo2: string;
  token1: string;
  token2: string;
}

const testData: Data[] = [
  {
    id: uuidv4(),
    name: "UNI/ETH",
    token1: "UNI",
    token2: "ETH",
    percent: "500",
    address: "0x7b47951986ebb66e5eb9d72b0f389f4ebb458e1a",
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    id: uuidv4(),
    name: "USDT/ETH",
    token1: "USDT",
    token2: "ETH",
    percent: "500",
    address: "0x0d2df9efa55b8b619282b32d4cc6192f38f50fff",
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    id: uuidv4(),
    name: "DAI/ETH",
    token1: "DAI",
    token2: "ETH",
    percent: "1000",
    address: "0xc2efad3800d58c379a5131627f3864f42c82af2e",
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    id: uuidv4(),
    name: "ETH/USDC",
    token1: "ETH",
    token2: "USDC",
    percent: "300",
    address: "0x8343fbf24ddb51583f5c260761ab8662221de6cc",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    id: uuidv4(),
    name: "ETH/WBTC",
    token1: "ETH",
    token2: "WBTC",
    percent: "300",
    address: "0x6cc805c617232ccb21166ff4c2b3f7431d3ff0d5",
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
];

export default testData;
