import { makeStyles } from "@material-ui/core";

const useStylesDropDown = makeStyles((theme) => ({
  conatiner: {
    backgroundColor: theme.palette.secondary.main,
    padding: "10px 10px",
    borderRadius: theme.shape.borderRadius,
  },
  ethIcon: {
    margin: "0px 4px",
    // height: 13,
    // width: 14,
    // height: 14,
  },
  buttonContainer: {
    paddingRight: 15,
  },
  buttonContent: {
    fontSize: "0.8rem",
    fontWeight: 500,
  },
}));

export default useStylesDropDown;
