import { makeStyles } from "@material-ui/core";

const useStylesInputMax = makeStyles((theme) => ({
  container: {
    // maxWidth: 200,
    backgroundColor: theme.palette.secondary.main, //"#F8F8F8",
    fontWeight: 500,
    padding: "4px 15px",
    borderRadius: theme.shape.borderRadius,
  },
  maxButton: {
    cursor: "pointer",
    textTransform: "lowercase",
    background: "rgba(0, 0, 0, 0.32)",
    padding: "5px 20px",
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.7rem",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  error: {
    color: "red",
  },
}));

export default useStylesInputMax;
