import { HypervisorTypes } from "../actionTypes";
import { HypervisorActions } from "../actions/hypervisor";
import { exponentialToDecimal } from "../../utils/formating";

export interface HypervisorState {
  amount0: number;
  amount1: number;
  token0Price: number;
  token1Price: number;
  input01: string;
  input02: string;
  maxAmount: {
    token0: string;
    token1: string;
  };
}

const initialState: HypervisorState = {
  amount0: 0,
  amount1: 0,
  token0Price: 1,
  token1Price: 1,
  input01: "0",
  input02: "0",
  maxAmount: {
    token0: "0",
    token1: "0",
  },
};

const hypervisorReducer = (
  state: HypervisorState = initialState,
  action: HypervisorActions
) => {
  switch (action.type) {
    case HypervisorTypes.GET_AMOUNTS:
      let { amount0, amount1 } = action.payload;

      let token0Price: number =
        parseFloat(amount1 ?? "1") / parseFloat(amount0 ?? "1");
      // parseFloat(amount0 ?? "1") / parseFloat(amount1 ?? "1");
      let token1Price: number =
        parseFloat(amount0 ?? "1") / parseFloat(amount1 ?? "1");
      // parseFloat(amount1 ?? "1") / parseFloat(amount0 ?? "1");
      if (
        (amount0 === "0" || amount0 === "0.0") &&
        (amount1 === "0" || amount1 === "0.0")
      ) {
        token0Price = 0;
        token1Price = 0;
      }

      return {
        ...state,
        token0Price,
        token1Price,
        amount0: parseFloat(amount0 ?? "0"),
        amount1: parseFloat(amount1 ?? "0"),
      };

    case HypervisorTypes.INPUT_01:
      var input01 = action.payload;
      var input02 = state.input02;
      if (state.token0Price !== 0) {
        input02 = exponentialToDecimal(
          (
            parseFloat(action.payload ? action.payload : "0") *
            state.token0Price
          ).toString()
        );
      }
      console.log("this is input 1", input01);

      return {
        ...state,
        input01,
        input02,
      };

    case HypervisorTypes.INPUT_02:
      var input01 = state.input01;
      var input02 = action.payload;

      if (state.token1Price !== 0) {
        input01 = exponentialToDecimal(
          (
            parseFloat(action.payload ? action.payload : "0") *
            state.token1Price
          ).toString()
        );
      }
      console.log("this is input 2", input02);
      return {
        ...state,
        input01,
        input02,
      };

    case HypervisorTypes.MAX_AMOUNT:
      let { maxAmount } = action.payload;

      return {
        ...state,
        maxAmount,
      };

    default:
      return state;
  }
};

export default hypervisorReducer;
