import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider as ReduxProvider } from "react-redux";

import { themeDark, themeLight } from "../theme/materialUiTheme";
import Layout from "./Layout";
import { getLibrary } from "../utils/walletConnectFunctions";
import { store } from "../redux";

function App() {
  return (
    <ReduxProvider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ThemeProvider theme={themeDark}>
          <CssBaseline />
          <Layout />
        </ThemeProvider>
      </Web3ReactProvider>
    </ReduxProvider>
  );
}

export default App;
