import React, { useState, useEffect } from "react";
import {
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useWeb3React } from "@web3-react/core";

import {
  DoubleToken,
  MigrateLiquidityTag,
  DisableElements,
  RowAlignCenter,
  ConnectWalletBtn,
  ContainerSpaceBetween,
  SelectToken,
  InputMax,
  CTooltip,
  ButtonIndicator,
} from "../../components";
import Dialog from "../../components/dialog/Dialog";
import ImportFrom from "../../components/dialog/ImportFrom";
import {
  Data,
  tokenAddrToName,
  WETH,
  WETH_ICON,
} from "../../components/dialog/dialogData";
import { FINANCE_PROTOCOL } from "../../utils/enums";

import { usePool } from "../../hooks/poolHooks";
import { useHypervisor, useLoading } from "../../hooks/";

import useStyles from "./styles";
import { deposit } from "../../contract/functions/hypervisor";
import { deposit as depositPopsicle } from "../../contract/functions/popsicle";
import { deposit as depositEth } from "../../contract/functions/lixor";
import { approve, getAllowance, toWei } from "../../contract/functions/erc20";
import { CONTRACT_ADDRESSES } from "../../config/Constants";
import { selectPlatform } from "../../redux";
import { trunc } from "../../utils/formating";

enum ButtonAction {
  ADD_LIQUIDITY = "Add liquidity",
  APPROVE_TOKEN0 = "Approve Token0",
  APPROVE_TOKEN1 = "Approve Token1",
  CONNECT_WALLET = "Connect Wallet",
}

interface IAvailable {
  contractAddress: string;
  protocol: string;
}

interface IInteract {
  contractAddress: string;
  token0Address: string;
  token1Address: string;
}

const Main = () => {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();

  // const tokens = {
  //   "0": "0xc778417e063141139fce010982780140aa0cd5ab",
  //   "1": "0xdd474436dfd48b9533d6afe91ce26cb6d2a9319c",
  // };

  const {
    getAmountsF,
    token0Price,
    token1Price,
    setInput01F,
    setInput02F,
    input01,
    input02,
    setMaxAmountF,
    maxAmount,
  } = useHypervisor();

  const { selectPoolF, selectedPool, selectPlatformF, selectedPlatform } =
    usePool();

  const details = selectedPool?.details;

  const { dappLoading } = useLoading();

  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  const [interact, setInteract] = useState<IInteract | null>(null);

  const [buttonText, setButtonText] = useState<ButtonAction>(
    ButtonAction.CONNECT_WALLET
  );

  const [open, setOpen] = useState<boolean>(false);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [approveAddr, setApproveAddr] = useState<string | undefined>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true);
  };

  const setItem = (value: Data) => {
    selectPoolF(value);
    selectPlatformF(undefined);
    setInteract(null);
    setOpen(false);
  };

  const setItemProtocol = (value: IAvailable | undefined) => {
    console.log("this is value: ", value);

    selectPlatformF(value);
    setInput01F("0");
    getAmountsF(value?.contractAddress, value?.protocol);

    // setOpenImportDialog(true);
  };

  const handleDeposit = async () => {
    if (interact) {
      console.log("this is interact: ", interact);
      if (buttonText === ButtonAction.APPROVE_TOKEN0) {
        return approveToken0();
      }

      if (buttonText === ButtonAction.APPROVE_TOKEN1) {
        return approveToken1();
      }

      let args = {
        callback: () => {
          console.log("tx completed");
          checkAllowance();
          getAmountsF(interact.contractAddress, selectedPlatform?.protocol);
        },
        dappLoading: "Pending",
        logMessage: "logMessage",
        txnMessage: "Pending",
        walletAddress: account ? account : "",
      };

      let amount0Prepare = await toWei(
        input01.toString(),
        interact.token0Address
      );
      let amount1prepare = await toWei(
        input02.toString(),
        interact.token1Address
      );

      if (
        selectedPlatform?.protocol === FINANCE_PROTOCOL.Lixor &&
        interact.token1Address.toLowerCase() === WETH.toLowerCase()
      ) {
        [amount0Prepare, amount1prepare] = [amount1prepare, amount0Prepare];
        console.log("swaped", input01, input02);
      }

      let argv = {
        account: account ?? "",
        amount0: amount0Prepare,
        amount1: amount1prepare,
        contractAddress: interact?.contractAddress ?? "",
        args,
      };

      console.log(argv);

      if (selectedPlatform?.protocol === FINANCE_PROTOCOL.Visor) {
        await deposit(argv);
      } else if (selectedPlatform?.protocol === FINANCE_PROTOCOL.Popsicle) {
        await depositPopsicle(argv);
      } else {
        await depositEth(argv);
      }
    }
  };

  const checkAllowance = async () => {
    if (interact) {
      let token0 = interact.token0Address ?? "";
      let token1 = interact.token1Address ?? "";

      if (
        !(
          selectedPlatform?.protocol == FINANCE_PROTOCOL.Lixor &&
          token0.toLowerCase() == WETH.toLowerCase()
        )
      ) {
        let token0Allowence = await getAllowance(
          token0,
          account ?? "",
          interact.contractAddress ?? ""
        );

        if (token0Allowence <= 0) {
          setApproveAddr(token0);
          return setButtonText(ButtonAction.APPROVE_TOKEN0);
        }
      }
      if (
        !(
          selectedPlatform?.protocol === FINANCE_PROTOCOL.Lixor &&
          token1.toLowerCase() === WETH.toLowerCase()
        )
      ) {
        let token1Allowence = await getAllowance(
          token1,
          account ?? "",
          interact.contractAddress ?? ""
        );

        if (token1Allowence <= 0) {
          console.log(
            "debug",
            selectedPlatform?.protocol,
            FINANCE_PROTOCOL.Lixor,
            token1.toLowerCase(),
            WETH.toLowerCase()
          );

          console.log("all tokn1", token1Allowence);
          setApproveAddr(token1);
          return setButtonText(ButtonAction.APPROVE_TOKEN1);
        }
      }
      setApproveAddr(undefined);
      setButtonText(ButtonAction.ADD_LIQUIDITY);
    }
  };

  const approveToken0 = async () => {
    if (interact) {
      await approve(
        interact.token0Address ?? "",
        account ?? "",
        interact.contractAddress ?? "",
        async () => {
          await checkAllowance();
        }
      );
    }
  };

  const approveToken1 = async () => {
    if (interact) {
      console.log(interact.token1Address, interact.contractAddress);

      await approve(
        interact.token1Address ?? "",
        account ?? "",
        interact.contractAddress ?? "",
        async () => {
          console.log("checkalll");

          await checkAllowance();
        }
      );
    }
  };

  // useEffect(() => {
  //   if (interact) {
  //     checkAllowance();

  //   }
  // }, [account, chainId, details]);

  useEffect(() => {
    const token0Address = details?.token0Address ?? "";
    const token1Address = details?.token1Address ?? "";

    const contractAddress = selectedPlatform?.contractAddress ?? "";

    console.log("this is selected protocol: ", selectedPlatform);

    setInteract({
      token0Address,
      token1Address,
      contractAddress,
    });

    // console.log(token0Address, token1Address, contractAddress);
  }, [selectedPlatform, details]);

  useEffect(() => {
    checkAllowance();
    if (interact) {
      let token0 = interact.token0Address ?? "";
      let token1 = interact.token1Address ?? "";

      if (
        selectedPlatform &&
        selectedPlatform?.protocol === FINANCE_PROTOCOL.Lixor
      ) {
        if (token0.toLowerCase() === WETH.toLowerCase()) {
          token0 = "ether";
        }
        if (token1.toLowerCase() == WETH.toLowerCase()) {
          token1 = "ether";
        }
      }
      console.log(token0, token1);

      setMaxAmountF(token0, token1, account ?? "");
      // console.log("interact", interact);
    }
  }, [interact, selectedPlatform]);

  // useEffect(() => {
  //   if (selectedPlatform && interact) {
  //     getAmountsF(interact.contractAddress, selectedPlatform?.protocol);
  //   }
  // }, [selectedPlatform, interact, account, dappLoading]);

  // console.log("=>", input01, input02, dappLoading);

  const getTokenName = (token2: string, tokenAddress: string) => {
    if (selectedPool) {
      if (
        selectedPlatform?.protocol == FINANCE_PROTOCOL.Visor &&
        tokenAddress.toLowerCase() == WETH.toLowerCase()
      ) {
        return "WETH";
      }

      return token2;
    }

    return "";
  };

  const getTokenURI = (logo: string, tokenAddress: string) => {
    if (selectedPool) {
      if (
        selectedPlatform?.protocol === FINANCE_PROTOCOL.Visor &&
        tokenAddress.toLowerCase() === WETH.toLowerCase()
      ) {
        return WETH_ICON;
      }

      return logo;
    }

    return "";
  };
  console.log("tokenPrice", token0Price, token1Price);

  return (
    <>
      <ImportFrom
        open={openImportDialog}
        close={() => setOpenImportDialog(!openImportDialog)}
        setItem={setItemProtocol}
        available={selectedPool?.details.available ?? []}
      />
      <Dialog open={open} close={() => setOpen(!open)} setItem={setItem} />
      <Container className={classes.root} maxWidth={"xs"}>
        <Box className={classes.conatiner}>
          <Box className={classes.visorBody}>
            <Box className={classes.mainHeading}>
              <Typography variant="h5"> Add Liquidity</Typography>
            </Box>

            <Box className={classes.mainContent}>
              <DisableElements disabled={!account || dappLoading !== ""}>
                <Box className={classes.dialogBtn}>
                  <Typography>Select Pair</Typography>
                  <Button
                    style={{ minWidth: xs_ ? 0 : 400 }}
                    onClick={handleOpen}
                    endIcon={
                      <ArrowDropDownIcon
                        style={{ justifyContent: "flex-end" }}
                      />
                    }
                  >
                    {selectedPool ? (
                      <Typography
                        style={{ textTransform: "capitalize", display: "flex" }}
                      >
                        <DoubleToken
                          token0Uri={selectedPool.logo1}
                          token1Uri={selectedPool.logo2}
                        />
                        <Box>{selectedPool.name}</Box>
                      </Typography>
                    ) : (
                      <Typography style={{ textTransform: "capitalize" }}>
                        Select Pools
                      </Typography>
                    )}
                  </Button>
                </Box>
                <Box
                  className={classes.dialogBtn}
                  style={{ marginTop: "25px" }}
                >
                  <Typography>Select Protocol</Typography>
                  <Button
                    style={{ minWidth: xs_ ? 0 : 400 }}
                    onClick={handleOpenImportDialog}
                    endIcon={
                      <ArrowDropDownIcon
                        style={{ justifyContent: "flex-end" }}
                      />
                    }
                  >
                    {selectedPlatform ? (
                      <MigrateLiquidityTag
                        protocol={selectedPlatform.protocol}
                        justify="flex-start"
                      />
                    ) : (
                      <Typography style={{ textTransform: "capitalize" }}>
                        Select Protocol
                      </Typography>
                    )}
                  </Button>
                </Box>

                <DisableElements
                  disabled={
                    !interact || dappLoading !== "" || !selectedPlatform
                  }
                >
                  <Box>
                    <ContainerSpaceBetween
                      className01={[
                        classes.marginTop,
                        classes.selectTokenInputMaxContainer,
                      ].join(" ")}
                      className02={classes.bottomSelect}
                      className03={classes.bottomInput}
                      Component01={
                        <SelectToken
                          viewOnly={true}
                          selected={selectedPool ? true : false}
                          rounded={true}
                          logoName={getTokenName(
                            selectedPool ? selectedPool.token1 : "",
                            details?.token0Address ?? ""
                          )}
                          logoUrl={getTokenURI(
                            selectedPool ? selectedPool.logo1 : "",
                            details?.token0Address ?? ""
                          )}
                          check={true}
                        />
                      }
                      Component02={
                        <InputMax
                          maxAmount={maxAmount.token0}
                          className={classes.inputMaxContainer}
                          setInput={setInput01F}
                          value={input01}
                          onClickMax={() => {
                            setInput01F(maxAmount.token0);
                          }}
                        />
                      }
                    />
                    <Box className={classes.textMarginTop} />
                    {/* <RowAlignCenter
                      containerClassName={classes.balanceHeight}
                      elements01={
                        <CTooltip title="this is test1">
                          <Typography variant="caption">Test1</Typography>
                        </CTooltip>
                      }
                      elements02={
                        token0DollarValue !== "" ? (
                          <CTooltip
                            title={exponentialToDecimal(
                              token0DollarValue.toString()
                            )}
                          >
                            <Typography variant="caption">{`~ $ ${trunc(
                              parseFloat(token0DollarValue)
                            )}`}</Typography>
                          </CTooltip>
                        ) : null
                      }
                    /> */}
                    <RowAlignCenter
                      containerClassName={classes.balanceHeight}
                      elements01={
                        <CTooltip title={maxAmount.token0}>
                          <Typography variant="caption">
                            Balance: {trunc(parseFloat(maxAmount.token0))}
                          </Typography>
                        </CTooltip>
                      }
                      elements02={
                        selectedPool ? (
                          <CTooltip
                            // title={exponentialToDecimal(
                            //   token1DollarValue.toString()
                            // )}
                            title={"title here"}
                          >
                            <Typography variant="caption">{""}</Typography>
                          </CTooltip>
                        ) : null
                      }
                    />
                  </Box>

                  <Box mt={"10px"} />

                  {/* <DisableElements disabled={!selectedPool}> */}
                  <Box>
                    <ContainerSpaceBetween
                      className01={[classes.selectTokenInputMaxContainer].join(
                        " "
                      )}
                      className02={classes.bottomSelect}
                      className03={classes.bottomInput}
                      Component01={
                        <SelectToken
                          viewOnly={true}
                          selected={selectedPool ? true : false}
                          rounded={true}
                          logoName={getTokenName(
                            selectedPool ? selectedPool.token2 : "",
                            details?.token1Address ?? ""
                          )}
                          logoUrl={getTokenURI(
                            selectedPool ? selectedPool.logo2 : "",
                            details?.token1Address ?? ""
                          )}
                          check={true}
                        />
                      }
                      Component02={
                        <InputMax
                          maxAmount={maxAmount.token1}
                          className={classes.inputMaxContainer}
                          setInput={setInput02F}
                          value={input02}
                          onClickMax={() => {
                            setInput02F(maxAmount.token1);
                          }}
                        />
                      }
                    />
                    <Box className={classes.textMarginTop} />
                    <RowAlignCenter
                      containerClassName={classes.balanceHeight}
                      elements01={
                        <CTooltip title={maxAmount.token1}>
                          <Typography variant="caption">
                            Balance: {trunc(parseFloat(maxAmount.token1))}
                          </Typography>
                        </CTooltip>
                      }
                      elements02={
                        selectedPool ? (
                          <CTooltip
                            // title={exponentialToDecimal(
                            //   token1DollarValue.toString()
                            // )}
                            title={"title here"}
                          >
                            <Typography variant="caption">{""}</Typography>
                          </CTooltip>
                        ) : null
                      }
                    />
                  </Box>
                </DisableElements>

                {/* </DisableElements> */}
              </DisableElements>

              <Box mt={"10px"} />

              {/* {_md && (
                <DisableElements
                  disabled={!(token0 && token1 && feeTier && !loading)}
                >
                  {allowCheck && <ItemList loading={loading} />}
                  <Box className={classes.marginTop} />
                </DisableElements>
              )} */}

              {account ? (
                <DisableElements
                  disabled={
                    !account ||
                    parseFloat(maxAmount.token0) <= 0 ||
                    parseFloat(maxAmount.token1) <= 0 ||
                    !interact ||
                    dappLoading !== "" ||
                    !selectedPlatform ||
                    parseFloat(input01) <= 0 ||
                    parseFloat(input02) <= 0
                  }
                >
                  <ButtonIndicator
                    disabled={dappLoading !== ""}
                    onClick={handleDeposit}
                    disableElevation
                    className={classes.buttonBottom}
                    variant="contained"
                    label={(() => {
                      if (approveAddr) {
                        return `Approve ${
                          tokenAddrToName[approveAddr.toLowerCase()]
                        }`;
                      }
                      return ButtonAction.ADD_LIQUIDITY;
                    })()}
                    fullWidth
                    color="primary"
                  />
                </DisableElements>
              ) : (
                <ConnectWalletBtn />
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Main;
