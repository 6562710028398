import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  ListItem,
  ListItemText,
  List,
  Drawer,
  IconButton,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Skeleton } from "@material-ui/lab";

import {
  Logo,
  LogoTick,
  WDialog,
  CloseButton,
  TokenLogo,
  RowAlignCenter,
  Identicon,
} from "../";
import useStyles from "./styles";
import { PILOT_LOGO, PILOT_ADDRESS } from "../../constants";
import { updatePilotPrice } from "../pilotLogo";
import { getNetworkName } from "../../utils/helpers";
import { conciseAddress } from "../../utils/formating";

interface IMainHeader {
  bottom?: boolean;
}

const MainHeader: React.FC<IMainHeader> = ({ bottom = false }) => {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();
  const { pathname } = useLocation();
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));

  const [price, setPrice] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const getPrice = async () => {
    const price = await updatePilotPrice();
    setPrice(price);
  };

  useEffect(() => {
    getPrice();
    setInterval(() => {
      getPrice();
    }, 30000);
  }, []);

  return (
    <>
      <WDialog open={open} close={() => setOpen(false)} />
      <Box className={classes.parentApp}>
        <AppBar
          className={`${bottom ? classes.goToBottom : classes.appBar} ${
            classes.bottomBorder
          }`}
          elevation={0}
          color={"transparent"}
          position={bottom ? "fixed" : "static"}
        >
          <Container className={classes.container}>
            <Toolbar
              className={bottom ? classes.toolBarAfter : classes.toolBarBefore}
            >
              {sm_ ? (
                <Link to="/">
                  <LogoTick
                    className={xs_ ? classes.logoTick_xs : classes.logoTick}
                  />
                </Link>
              ) : (
                <Link
                  to="/"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Logo className={classes.logo} />
                </Link>
              )}

              <Box marginLeft="auto" display="flex" alignItems="center">
                {!xs_ ? (
                  <Grid
                    container
                    justify={!sm_ ? "flex-end" : undefined}
                    direction={sm_ ? "row-reverse" : "row"}
                  >
                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <Link
                            target="_blank"
                            to="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x37c997b35c619c21323f3518b9357914e8b99525"
                          >
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              style={{ marginTop: "-7px" }}
                            >
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={PILOT_LOGO}
                                  alt="logo"
                                  width="25px"
                                  height="25px"
                                />
                              </Grid>
                              <Grid
                                item
                                style={{
                                  padding: 0,
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    color: `${theme.palette.primary.main}`,
                                  }}
                                >
                                  Pilot
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{ fontWeight: 700, color: "#fff" }}
                              >
                                ${price > 0 ? price : null}
                              </Grid>
                            </Grid>
                          </Link>
                        </Grid>
                        {account ? (
                          chainId === 4 ? (
                            <Grid
                              item
                              className={classes.connectBtn}
                              style={{ padding: "0px 15px 3px 15px" }}
                            >
                              <Typography
                                style={{
                                  color: theme.palette.primary.main,
                                  marginTop: "3px",
                                  fontWeight: 500,
                                }}
                              >
                                {getNetworkName(chainId)}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              className={classes.connectBtn}
                              style={{
                                paddingTop: "0",
                                borderColor: "#CF352E",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "rgb(207, 53, 46)",
                                  marginTop: "3px",
                                  fontWeight: 500,
                                }}
                              >
                                {getNetworkName(chainId)}
                              </Typography>
                            </Grid>
                          )
                        ) : null}
                        <Grid
                          item
                          className={classes.connectBtn}
                          style={{ padding: 0 }}
                        >
                          <Button onClick={handleOpen}>
                            {account ? (
                              <Typography style={{ textTransform: "none" }}>
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item>
                                    <Identicon />
                                  </Grid>
                                  <Grid item>{conciseAddress(account)}</Grid>
                                </Grid>
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  textTransform: "none",
                                  padding: 3,
                                  fontWeight: 500,
                                }}
                              >
                                Connect Wallet
                              </Typography>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    alignItems="center"
                    justify={!sm_ ? "flex-end" : undefined}
                    direction={sm_ ? "row-reverse" : "row"}
                    spacing={1}
                  >
                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid
                          item
                          className={classes.connectBtn}
                          style={{ padding: 0 }}
                        >
                          <Button onClick={handleOpen}>
                            <Typography style={{ textTransform: "none" }}>
                              {account ? (
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item>
                                    <Identicon />
                                  </Grid>
                                  <Grid item>{conciseAddress(account)}</Grid>
                                </Grid>
                              ) : (
                                "Connect Wallet"
                              )}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};

export default MainHeader;
