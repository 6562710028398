import { Tooltip, Box } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

interface ICTooltip {
  title: string;
  children: JSX.Element;
}

const CTooltip: React.FC<ICTooltip> = ({ title, children }) => {
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltip }} arrow>
      {children}
    </Tooltip>
  );
};

export default CTooltip;
