import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  conatiner: {
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(0,0,0,0.5)"
        : theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: "1.3rem",
    [theme.breakpoints.up("sm")]: {
      minHeight: "550px",
      marginTop: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "100px",
    },
    "@media(max-width:770px)": {
      marginTop: "15px",
    },
  },
  visorBody: {},
  dialogBtn: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiButton-root": {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiButton-label .MuiButton-endIcon": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: 100,
      },
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& > p": {
      marginBottom: "10px",
    },
  },
  mainContent: {
    marginTop: "30px",
  },
  marginTop: {
    marginTop: 20,
  },
  selectTokenInputMaxContainer: {
    background: "linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: theme.shape.borderRadius,
  },
  bottomSelect: {
    borderRadius: 0,
  },
  bottomInput: {
    borderRadius: 0,
  },
  inputMaxContainer: {
    // backgroundColor: theme.palette.secondary.main, //"#F8F8F8",
    fontWeight: 500,
    padding: "4px 20px",
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    // borderLeft: `2px solid rgba(255,255,255,0.1)`,
    minHeight: 50,
  },
  textMarginTop: {
    marginTop: 5,
  },
  balanceHeight: {
    height: 24,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
    // backgroundColor: theme.palette.common?.purplish,
  },
  mainHeading: {
    "& h5": {
      // marginBottom: "15px",
      textAlign: "center",
      fontWeight: "600",
    },
  },
}));

export default useStyles;
