export const CONTRACT_ADDRESSES = {
  flashLiquidity: "0x2a10D07714f94643c6627a5b9CD56655D7F0d01b",
  //balance: '0x6B9aE204B9779fbd15Fdbd027c9F0d266A32f0b7',
  balance: "0x84Dc72D9Ce4Bf5cA2b1E6e7a3d57C16c723cf0f3",
  nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  multicall: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
  hypervisor: "0x2dc27501d20d3a7d3bf1e4a1ccebf40445f11e5b",
  lixor: "0x887D5DA873Ec6fF69C77EF0168E56B10472e3Bf4",
};
export const CHAIN_ID =
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? 1 : 4;

export const UN_KNOWN_LOGO =
  "https://gateway.pinata.cloud/ipfs/QmNX2QerTxTm1RThD7Dc9X5uS9VFnQxmMotaMFhK5GYbBk";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const ETHERSCAN_TX_BASE_URL =
  CHAIN_ID === 1
    ? "https://etherscan.io/tx/"
    : "https://rinkeby.etherscan.io/tx/";

export const DEFAULT_SLIPPAGE = "0.5";
export const DEFAULT_DEADLINE = "30";

export const GRAPH_END_POINT =
  CHAIN_ID === 1
    ? "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3"
    : "https://api.thegraph.com/subgraphs/name/mubashir-ali-baig/uniswap-v3-subgraph";

export const DEFAULT_TOKEN_LIST = CHAIN_ID === 1 ? 11 : 12;

export const PILOT_ADDRESS = "0x37c997b35c619c21323f3518b9357914e8b99525";
export const PILOT_LOGO =
  "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz";
