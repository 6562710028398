import { useSelector } from "react-redux";

import { useDispatchWrap } from "./utilHook";
import { selectPlatform, selectPool, selectProtocol, State } from "../redux";

export const usePool = () => {
  const pools = useSelector((state: State) => state.pools);

  const selectPoolF = useDispatchWrap(selectPool);
  const selectProtocolF = useDispatchWrap(selectProtocol);
  const selectPlatformF = useDispatchWrap(selectPlatform);

  return {
    ...pools,
    selectPoolF,
    selectProtocolF,
    selectPlatformF,
  };
};
