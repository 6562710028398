import { makeStyles } from "@material-ui/core";

const useStylesButtonIndicator = makeStyles((theme) => ({
  wrapper: {
    // margin: theme.spacing(1),
    position: "relative",
    // backgroundColor: "red",
  },
  fabProgress: {
    color: "green",
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    // color: "green",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2nd: {
    marginRight: 10,
  },
}));

export default useStylesButtonIndicator;
