import { makeStyles } from "@material-ui/core";

const useStylesSelectToken = makeStyles((theme: any) => {
  return {
    containerUnselected: {
      padding: "10.5px 20px",
      textTransform: "none",
      justifyContent: "space-between",
      borderRadius: theme.shape.borderRadius,
      minHeight: 50,
    },
    containerSelected: {
      color: "white",
      //padding: "5px 20px",
      textTransform: "none",
      justifyContent: "space-between",
      borderRadius: theme.shape.borderRadius,
      // backgroundColor: theme.palette.secondary.main,
      minHeight: 50,
      // background: "red",
      background:
        "linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)",
      border: "1px solid rgba(255, 255, 255, 0.05)",
      "&:hover": {
        border: "1px solid rgba(255, 255, 255, 0.05)",
      },
    },
    avatar: {
      width: 14,
      height: 14,
      margin: "0px 5px",
    },
    typoPri: {
      fontSize: "0.8rem",
      fontWeight: 500,
      margin: "0px 5px",
    },
    viewOnly: {
      "&:hover": {
        background: "rgba(0, 0, 0, 0.32)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        cursor: "default",
      },
      borderRadius: theme.shape.borderRadius,
      background: "rgba(0, 0, 0, 0.32)",
      color: "white",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      "@media(max-width:550px)": {
        padding: "0 5px",
      },
    },
    roundedViewOnly: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
    roundSelected: {
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  };
});

export default useStylesSelectToken;
