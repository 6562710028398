import { useSelector } from "react-redux";
import { useDispatchWrap } from "./utilHook";
import {
  getAmounts,
  setInput01,
  setInput02,
  setMaxAmount,
  State,
} from "../redux";

export const useHypervisor = () => {
  const hypervisor = useSelector((state: State) => state.hypervisor);

  const getAmountsF = useDispatchWrap(getAmounts);
  const setInput01F = useDispatchWrap(setInput01);
  const setInput02F = useDispatchWrap(setInput02);
  const setMaxAmountF = useDispatchWrap(setMaxAmount);

  return {
    ...hypervisor,
    getAmountsF,
    setInput01F,
    setInput02F,
    setMaxAmountF,
  };
};
