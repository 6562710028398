import {
  Snackbar as SnackbarMui,
  IconButton,
  Slide,
  Link,
  Grid,
} from "@material-ui/core";
import { ClearOutlined, LinkOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

import { useStyles } from "./styles";
import { useSnackbar } from "../../hooks";
import { ETHERSCAN_TX_BASE_URL } from "../../config/Constants";

const Snack: React.FC<{}> = () => {
  const classes = useStyles();
  const { open, message, severity, txHash, hideSnackbarF } = useSnackbar();

  const TransitionUp = (props: any) => {
    return <Slide {...props} direction="up" />;
  };

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={60000}
      onClose={hideSnackbarF}
      TransitionComponent={TransitionUp}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={hideSnackbarF}
        >
          <ClearOutlined fontSize="small" />
        </IconButton>
      }
    >
      <Alert
        onClose={hideSnackbarF}
        severity={severity}
        className={classes.snackbarStyles}
        style={open ? {} : { display: "none" }}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item>{message}</Grid>
          {txHash && (
            <Grid item>
              <Link
                component="a"
                className={classes.etherscan}
                href={ETHERSCAN_TX_BASE_URL + txHash}
                target="_blank"
                rel="noreferrer"
              >
                <LinkOutlined /> <span>View on etherscan</span>
              </Link>
            </Grid>
          )}
        </Grid>
      </Alert>
    </SnackbarMui>
  );
};

export default Snack;
