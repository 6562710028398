import Web3 from "web3";
import { BigNumber } from "ethers";
import { Token } from "@uniswap/sdk-core";

import { IToken } from "./generalTypes";
import { parseAmount } from "./formating";

export const getNetworkName = (chainId: number | undefined): string => {
  if (chainId === 1) return "Mainnet";
  else if (chainId === 3) return "Ropsten";
  else if (chainId === 4) return "Rinkeby";
  else if (chainId === 5) return "Goerli";
  else if (chainId === 42) return "Kovan";
  else return "Wrong Newtwork";
};

export const DEFAULT_TOKEN: IToken = {
  address: "",
  name: "",
  symbol: "",
  decimals: 0,
};

export const validAmount = (token: IToken, amount: string) => {
  if (token?.balance) {
    return parseFloat(token?.balance) <
      parseFloat(parseAmount(amount, token.decimals))
      ? true
      : false;
  } else return false;
};

export const getTokens = (token0: string, token1: string) => {
  const _token0 = Web3.utils.toChecksumAddress(token0);
  const _token1 = Web3.utils.toChecksumAddress(token1);
  if (_token0 > _token1) {
    return { token0: token1, token1: token0 };
  } else {
    return { token0, token1 };
  }
};

export const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);

export const getLogoUri = (tokenAddress: string) => {
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
};

export const DAI = new Token(
  1,
  "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  18,
  "DAI",
  "Dai Stablecoin"
);
export const USDC = new Token(
  1,
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  6,
  "USDC",
  "USD//C"
);
export const USDT = new Token(
  1,
  "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  "USDT",
  "Tether USD"
);
export const WBTC = new Token(
  1,
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  8,
  "WBTC",
  "Wrapped BTC"
);
