import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  goToBottom: {
    top: "5px",
    // bottom: 0,
  },
  bottomToolbar: {
    // ...theme.mixins.toolbar,
    // minHeight: 60,
    // backgroundColor: "red",
    // [theme.breakpoints.down("sm")]: {
    //   ...theme.mixins.toolbar,
    // },
  },
  ethContainer: {
    backgroundColor: "black",
    padding: "10px 15px",
    borderRadius: 10,
  },
  ethTypo: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    letterSpacing: "0.05rem",
    marginLeft: 5,
  },
  addr: {
    fontSize: "0.9rem",
    marginLeft: 20,
    fontWeight: "bold",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgSizeBottom: {
    "&:hover": {
      fill: "#9A56FF",
      cursor: "pointer",
    },
  },
  iconSvg: {
    width: 18,
    height: 18,
    // backgroundColor: "red",
    "&:hover": {
      cursor: "pointer",
      fill: theme.palette.primary.main,
    },
    "&:hover  .bicon": {
      cursor: "pointer",
      fill: theme.palette.primary.main,
    },
  },

  logoText: {
    marginLeft: 10,
    fontSize: "1.3rem",
    fontWeight: 500,
  },
  adjustBottom: {
    marginTop: 100,
  },
}));

export default useStyles;
