import { Box, Dialog as MuiDialog } from '@material-ui/core';
import useStyles from './styles';

interface IDialog {
  open: boolean;
  close: () => void;
  props: any;
  children: any;
}

const IMDialog: React.FC<IDialog | any> = ({ open, close, children, ...props }) => {
  const classes = useStyles();

  return (
    <MuiDialog
      // classes={{ container: classes.MUIContainer }}
      open={open}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      {...props}
    >
      {children}
    </MuiDialog>
  );
};

export default IMDialog;
