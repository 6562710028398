export enum MIGRATE_PROTOCOL {
  UniswapV2 = "UniswapV2",
  UniswapV3 = "UniswapV3",
  SushiSwap = "SushiSwap",
}

export enum FINANCE_PROTOCOL {
  Visor = "Visor",
  Lixor = "Lixor",
  Popsicle = "Popsicle",
}

export enum TRANSACTION_STATUS {
  PENDING = "pending",
  SUCCESS = "Success",
  FAILED = "Failed",
}
