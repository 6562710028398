import { HypervisorContract } from "../getContract";
import { logError, logMessage } from "../../utils/logs";
import { CONTRACT_ADDRESSES } from "../../config/Constants";
import {
  transactionFailed,
  transactionPending,
  transactionRejected,
  transactionSuccess,
} from "../../redux";
import { TRANSACTION_STATUS } from "../../utils/enums";
import {
  IDepositFunc,
  IGetTotalAmountsFunc,
  TransactionReceipt,
} from "../../utils/generalTypes";
import { getDecimals } from "./erc20";
import { formatAmount } from "../../utils/formating";

export const getTotalAmounts: IGetTotalAmountsFunc = async (
  contractAddress: string
) => {
  try {
    const contract = HypervisorContract(contractAddress);

    let amounts = await contract?.methods.getTotalAmounts().call();
    let token0 = await contract?.methods.token0().call();
    let token1 = await contract?.methods.token1().call();

    let d0 = await getDecimals(token0 ?? "");
    let d1 = await getDecimals(token1 ?? "");

    return {
      amount0: formatAmount(amounts?.total0 ?? "", d0),
      amount1: formatAmount(amounts?.total1 ?? "", d1),
    };
  } catch (e) {
    logError("deposit", e);
    return {
      amount0: undefined,
      amount1: undefined,
    };
  }
};

export const deposit: IDepositFunc = async ({
  amount0,
  amount1,
  account,
  contractAddress,
  args,
}) => {
  try {
    const { walletAddress, txnMessage, logMessage, dappLoading, callback } =
      args;

    let transaction = {
      hash: "",
      account: "",
      message: "",
      status: TRANSACTION_STATUS.PENDING,
      checkCount: 0,
    };

    // console.log(amount0, amount1, account);
    const contract = HypervisorContract(contractAddress);

    await contract?.methods
      .deposit(amount0, amount1, account)
      .send({
        from: account,
      })
      .on("transactionHash", (txnHash: string) => {
        transaction = {
          hash: txnHash,
          account: walletAddress,
          message: txnMessage,
          status: TRANSACTION_STATUS.PENDING,
          checkCount: 0,
        };
        transactionPending(
          txnHash,
          { link: txnHash, text: "text", secondaryText: "sec" },
          dappLoading
        );
      })
      .then((receipt: TransactionReceipt) => {
        transaction["status"] = TRANSACTION_STATUS.SUCCESS;
        transactionSuccess(receipt.transactionHash, {
          link: receipt.transactionHash,
          text: "text",
          secondaryText: "sec",
        });
        callback();
      })
      .catch((e: any) => {
        if (e.code === 4001) {
          transactionRejected({
            link: transaction.hash,
            text: "text",
            secondaryText: "sec",
          });
        } else {
          transaction["status"] = TRANSACTION_STATUS.FAILED;

          transactionFailed({
            link: transaction.hash,
            text: "text",
            secondaryText: "sec",
          });
        }
        logError(`${logMessage}-e`, e);
      });
  } catch (e) {
    logError("deposit", e);
  }
};

// export const createPoolAndDeposit = async (
//   params: ILiquidity['createAndAdd'],
//   feeTier: string,
//   sqrtPrice: string,
//   token0Symbol: string,
//   token1Symbol: string,
//   walletAddress: string,
//   ethValue?: string,
//   callback: any = () => {}
// ) => {
//   try {
//     logMessage('createPoolAndDeposit', params);
//     const contract = unipilotContract();
//     const bytesCreate = encodeParameters(['uint24', 'uint160'], [feeTier, sqrtPrice]);
//     const bytesDeposite = encodeParameters(['uint24', 'uint256'], [feeTier, '0']);
//     const contractFnc = contract?.methods.createPoolAndDeposit(params, [
//       bytesCreate,
//       bytesDeposite,
//     ]);
//     const args = {
//       walletAddress,
//       txnMessage: `Create pool & add liquidity ${token0Symbol}/${token1Symbol}`,
//       logMessage: 'createPoolAndDeposit',
//       dappLoading: LOADING.ADD_LIQUIDITY,
//       ethValue,
//       callback,
//     };
//     if (contractFnc) {
//       await sendTransaction(contractFnc, args);
//     }
//   } catch (e) {
//     logError('createPoolAndDeposit', e);
//   }
// };

// export const balanceOf = async (walletAddress: string): Promise<string | undefined> => {
//   try {
//     const contract = unipilotContract();
//     const balance = await contract?.methods.balanceOf(walletAddress).call();
//     return balance;
//   } catch (e) {
//     logError('balanceOf', e);
//   }
// };

// export const tokenOfOwnerByIndex = async (
//   call: CALL_TYPE,
//   [balance, walletAddress]: [number, string]
// ) => {
//   const types: DecodeType[] = [
//     {
//       type: 'uint256',
//       name: '',
//     },
//   ];
//   const contract = unipilotContract();
//   const method = contract?.methods.tokenOfOwnerByIndex;
//   if (call === CALL_TYPE.MULTI) {
//     const indexes = [...Array(balance).keys()].map(index => [walletAddress, index]);
//     return await singleContractMultipleData(
//       indexes,
//       CONTRACT_ADDRESSES.unipilot,
//       method,
//       types,
//       'multi-tokenOfOwnerByIndex '
//     );
//   } else if (call === CALL_TYPE.SINGLE) {
//     return await singleCall([walletAddress, balance], method, 'single-tokenOfOwnerByIndex');
//   }
// };

// export const findNFTPositions = async (
//   walletAddress: string
// ): Promise<INFTPosition[] | undefined> => {
//   try {
//     //get user nft balance
//     const balance = await balanceOf(walletAddress);

//     //get nft ids w.r.t index
//     const tokenIds =
//       (await tokenOfOwnerByIndex(CALL_TYPE.MULTI, [Number(balance ?? '0'), walletAddress])) ?? [];

//     //get positions from nft id's
//     const positionDetails =
//       (await getPositionDetails(CALL_TYPE.MULTI, tokenIds, [
//         'liquidity',
//         'pool',
//         'token0',
//         'token1',
//         'fee',
//         'currentTick',
//         'amount0',
//         'amount1',
//         'fee0',
//         'fee1',
//         'totalLiquidity',
//       ])) ?? [];

//     //extract tokenAddresses from positions
//     const tokenAddresses = positionDetails.flatMap(({ token0, token1 }: any) => [token0, token1]);

//     //extract poolAddresses from positions
//     const poolAddresses = positionDetails.map(({ pool }: any) => pool);

//     //get tokens details from tokenAddresses
//     const tokens = await tokenDetail(tokenAddresses, walletAddress);

//     //get base and range ticks
//     const liquidityPosition = await liquidityPositions(CALL_TYPE.MULTI, poolAddresses, [
//       'baseTickLower',
//       'baseTickUpper',
//       'rangeTickLower',
//       'rangeTickUpper',
//     ]);

//     //get position rebasing
//     const rebasing = await shouldRebase(CALL_TYPE.MULTI, poolAddresses, ['readjust']);

//     const rewards = await fetchNftClaimedReward(tokenIds);
//     let newPositions: INFTPosition[] = [];

//     for (let i = 0; i < tokenIds.length; i++) {
//       const {
//         liquidity,
//         pool: poolAddress,
//         token0: token0Address,
//         token1: token1Address,
//         fee,
//         currentTick,
//         amount0,
//         amount1,
//         fee0: token0Fee,
//         fee1: token1Fee,
//         totalLiquidity,
//       } = positionDetails[i];
//       const { baseTickLower, baseTickUpper, rangeTickLower, rangeTickUpper } = liquidityPosition[i];
//       const token0 = tokens[token0Address.toLowerCase()];
//       const token1 = tokens[token1Address.toLowerCase()];
//       const tokenId = tokenIds[i];
//       const inRange = !rebasing[i].readjust;
//       const reward = rewards ? formatAmount(rewards[tokenId] ?? '0', 18) : '0';
//       let allowRebase = false;
//       let rebaseIncentive = false;
//       if (!inRange) {
//         allowRebase = await rebaseFrequency(poolAddress);
//         rebaseIncentive = await checkPoolValidation(token0Address, token1Address, amount0, amount1);
//       }

//       //arrange all data into single  position
//       newPositions.push({
//         tokenId,
//         poolAddress,
//         token0,
//         token1,
//         fee,
//         liquidity,
//         totalLiquidity,
//         token0Fee,
//         token1Fee,
//         amount0,
//         amount1,
//         currentTick,
//         inRange,
//         rebaseIncentive,
//         allowRebase,
//         baseTickLower,
//         baseTickUpper,
//         rangeTickLower,
//         rangeTickUpper,
//         reward,
//       });
//     }
//     return newPositions;
//   } catch (e) {
//     logError('findNFTPositions', e);
//   }
// };

// export const nftPositionInterval = async (
//   tokenIds: number[],
//   walletAddress: string
// ): Promise<INFTPositionInterval[] | undefined> => {
//   try {
//     const positionDetails = await getPositionDetails(CALL_TYPE.MULTI, tokenIds, [
//       'pool',
//       'liquidity',
//       'token0',
//       'token1',
//       'fee',
//       'currentTick',
//       'amount0',
//       'amount1',
//       'fee0',
//       'fee1',
//       'totalLiquidity',
//     ]);

//     //extract tokenAddresses from positions
//     const tokenAddresses = positionDetails.flatMap(({ token0, token1 }: any) => [token0, token1]);

//     //extract poolAddresses from positions
//     const poolAddresses = positionDetails.map(({ pool }: any) => pool);

//     //get tokens details from tokenAddresses
//     const tokens = await tokenDetail(tokenAddresses, walletAddress);

//     //get base and range ticks
//     const liquidityPosition = await liquidityPositions(CALL_TYPE.MULTI, poolAddresses, [
//       'baseTickLower',
//       'baseTickUpper',
//       'rangeTickLower',
//       'rangeTickUpper',
//     ]);

//     //get position rebasing
//     const rebasing = await shouldRebase(CALL_TYPE.MULTI, poolAddresses, ['readjust']);

//     let newPositions: INFTPositionInterval[] = [];

//     for (let i = 0; i < tokenIds.length; i++) {
//       const {
//         pool: poolAddress,
//         liquidity,
//         token0: token0Address,
//         token1: token1Address,
//         fee,
//         currentTick,
//         amount0,
//         amount1,
//         fee0: token0Fee,
//         fee1: token1Fee,
//         totalLiquidity,
//       } = positionDetails[i];
//       const { baseTickLower, baseTickUpper, rangeTickLower, rangeTickUpper } = liquidityPosition[i];
//       const token0Balance = tokens[token0Address.toLowerCase()].balance;
//       const token1Balance = tokens[token1Address.toLowerCase()].balance;
//       const inRange = !rebasing[i].readjust;
//       let allowRebase = false;
//       let rebaseIncentive = false;
//       if (!inRange) {
//         allowRebase = await rebaseFrequency(poolAddress);
//         rebaseIncentive = await checkPoolValidation(token0Address, token1Address, amount0, amount1);
//       }
//       newPositions.push({
//         tokenId: tokenIds[i],
//         poolAddress,
//         token0Balance,
//         token1Balance,
//         fee,
//         liquidity,
//         totalLiquidity,
//         token0Fee,
//         token1Fee,
//         amount0,
//         amount1,
//         currentTick,
//         inRange,
//         rebaseIncentive,
//         allowRebase,
//         baseTickLower,
//         baseTickUpper,
//         rangeTickLower,
//         rangeTickUpper,
//       });
//     }

//     return newPositions;
//   } catch (e) {
//     logError('nftPositionInterval', e);
//   }
// };

// export const removeLiquidity = async (
//   params: ILiquidity['remove'],
//   tokenId: string,
//   walletAddress: string,
//   token0Symbol: string,
//   token1Symbol: string,
//   addToken: boolean,
//   callback: any = () => {}
// ) => {
//   try {
//     logMessage('Remove Liquidity', params);
//     const contract = unipilotContract();
//     const bytes = encodeParameters(['address'], [walletAddress]);
//     logMessage('Remove Liquidity Bytes', bytes);
//     const contractFnc = contract?.methods.withdraw(params, bytes);
//     const args = {
//       walletAddress,
//       txnMessage: `Removed liquidity ${token0Symbol}/${token1Symbol}`,
//       logMessage: 'removeLiquidity',
//       dappLoading: `${LOADING.REMOVE_LIQUIDITY}-${tokenId}`,
//       addToken,
//       callback,
//     };
//     if (contractFnc) {
//       await sendTransaction(contractFnc, args);
//     }
//   } catch (e) {
//     logError('removeLiquidity', e);
//   }
// };

// export const collect = async (
//   params: ILiquidity['collect'],
//   tokenId: string,
//   walletAddress: string,
//   token0Symbol: string,
//   token1Symbol: string,
//   addToken: boolean,
//   callback: any = () => {}
// ) => {
//   try {
//     const contract = unipilotContract();
//     const bytes = encodeParameters(['address'], [walletAddress]);
//     const contractFnc = contract?.methods.collect(params, bytes);
//     const args = {
//       walletAddress,
//       txnMessage: `Collect fee ${token0Symbol}/${token1Symbol}`,
//       logMessage: 'collect',
//       dappLoading: `${LOADING.COLLECT_FEE}-${tokenId}`,
//       addToken,
//       callback,
//     };
//     if (contractFnc) {
//       await sendTransaction(contractFnc, args);
//     }
//   } catch (e) {
//     logError('collect', e);
//   }
// };

// export const approveUnipilotNft = async (
//   tokenId: string,
//   to: string,
//   walletAddress: string,
//   callback: any = () => {}
// ) => {
//   try {
//     const contract = unipilotContract();
//     const contractFnc = contract?.methods.approve(to, tokenId);
//     const args = {
//       walletAddress,
//       txnMessage: `Approved Unipilot NFT`,
//       logMessage: 'approveUnipilotNft',
//       dappLoading: `${LOADING.APPROVE}-${tokenId}`,
//       callback,
//     };
//     if (contractFnc) {
//       await sendTransaction(contractFnc, args);
//     }
//   } catch (e) {
//     logError('approveUnipilotNft', e);
//   }
// };

// export const checkUnipilotNftApproval = async (tokenId: string, approvedTo: string) => {
//   try {
//     const contract = unipilotContract();
//     const approvedAddress = await contract?.methods.getApproved(tokenId).call();
//     return approvedAddress?.toLowerCase() === approvedTo.toLowerCase();
//   } catch (e) {
//     logError('checkUnipilotNftApproval', e);
//     return false;
//   }
// };
