import {
  Dialog as MuiDialog,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Grid,
  Divider,
} from "@material-ui/core";

import { MigrateLiquidityTag } from "../../components";
import useStyles from "./styles";
import { CloseButton } from "../index";
import { MIGRATE_PROTOCOL, FINANCE_PROTOCOL } from "../../utils/enums";
import { useEffect } from "react";

interface IAvailable {
  contractAddress: string;
  protocol: string;
}

interface IImportFrom {
  open: boolean;
  close: () => void;
  setItem: (value: IAvailable | undefined) => void;
  available: IAvailable[];
}

// const Protocols = [MIGRATE_PROTOCOL.UniswapV2, MIGRATE_PROTOCOL.SushiSwap];
const Protocols: FINANCE_PROTOCOL | string[] = [
  FINANCE_PROTOCOL.Visor,
  FINANCE_PROTOCOL.Lixor,
  FINANCE_PROTOCOL.Popsicle,
];

const ImportFrom: React.FC<IImportFrom> = ({
  open,
  close,
  setItem,
  available,
}) => {
  const classes = useStyles();

  const handleClose = (): void => {
    close();
  };

  const filtered = available.filter((protocol) =>
    Protocols.includes(protocol?.protocol)
  );

  // useEffect(() => {
  //   setItem(undefined);
  // }, [available]);

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      maxWidth="xl"
    >
      <Container className={classes.container} maxWidth="xs">
        <Box className={classes.box02}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h6">Select Protocol</Typography>
            </Grid>
            <Grid item>
              <CloseButton onClick={close} />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className={classes.listContainer}>
          <List>
            {filtered.map((protocol) => (
              <ListItem
                key={protocol.protocol}
                onClick={() => {
                  // console.log("protocol here: ", protocol);
                  setItem(protocol);
                  handleClose();
                }}
                className={`${classes.listItemIm}`}
              >
                <MigrateLiquidityTag
                  justify="flex-start"
                  protocol={protocol.protocol}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>
    </MuiDialog>
  );
};

export default ImportFrom;
