import { v4 as uuidv4 } from "uuid";
import { FINANCE_PROTOCOL } from "../../utils/enums";

export interface Data {
  id: string;
  name: string;
  percent: string;
  address: string;
  logo1: string;
  logo2: string;
  token1: string;
  token2: string;
  details: {
    token0Address: string;
    token1Address: string;
    contractAddress: string;
    available: {
      contractAddress: string;
      protocol: string;
    }[];
  };
}

const DAI = "0x7a691d02726774f4fB933346A4ae6f17BfCC0D47";
const USDC = "0x47aBDfF3A3a7c05B3293C35802DA39E6010E7A1e";
const WBTC = "0xc4Cb39395dc41d81DE298Becebdf7e147F5Fc411";
const USDT = "0xF232B64b74Aaf92379c1D920fE7262Bed9ae1e7e";
export const WETH = "0xc778417E063141139Fce010982780140Aa0cD5Ab";
const UNI = "0x317a8b58C40445B997147254E8584F0EE3A1357E";
const PILOT = "0x18F10404cC1414e6ACBfEA16CCC97D9ed303e98C";

const DAI_ICON =
  "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png";
export const ETH_ICON =
  "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip";
const USDT_ICON =
  "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png";
const USDC_ICON =
  "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png";
const WBTC_ICON =
  "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png";
const UNI_ICON =
  "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png";
export const WETH_ICON =
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png";

const UNIPILOT_ICON =
  "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz";

export const tokenAddrToName = {
  [DAI.toLowerCase()]: "DAI",
  [USDC.toLowerCase()]: "USDC",
  [WBTC.toLowerCase()]: "WBTC",
  [USDT.toLowerCase()]: "USDT",
  [UNI.toLowerCase()]: "UNI",
  [WETH.toLowerCase()]: "WETH",
  [PILOT.toLowerCase()]: "PILOT",
  [""]: "",
};

const testData: Data[] = [
  {
    id: uuidv4(),
    name: "UNI/ETH",
    token1: "UNI",
    token2: "ETH",
    percent: "0.3%",
    address: "0x86c8f401d4986aa3c4d891b8b16949320df6a40e",
    logo1: UNI_ICON,
    logo2: ETH_ICON,
    details: {
      token0Address: UNI,
      token1Address: WETH,
      contractAddress: "0x86c8f401d4986aa3c4d891b8b16949320df6a40e",
      available: [
        {
          contractAddress: "0x86c8f401d4986aa3c4d891b8b16949320df6a40e",
          protocol: FINANCE_PROTOCOL.Visor,
        },
        // {
        //   contractAddress: "0xBFa7697Db8D5C0cDf464814C6798D3C1aa997c9E",
        //   protocol: FINANCE_PROTOCOL.Lixor,
        // },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "ETH/USDT",
    token1: "ETH",
    token2: "USDT",
    percent: "0.3%",
    address: "0x6d36ab4e6f02331c60d28cf633db2b5b59acca20",
    logo1: ETH_ICON,
    logo2: USDT_ICON,
    details: {
      token0Address: WETH,
      token1Address: USDT,
      contractAddress: "0x6d36ab4e6f02331c60d28cf633db2b5b59acca20",
      available: [
        {
          contractAddress: "0x6d36ab4e6f02331c60d28cf633db2b5b59acca20",
          protocol: FINANCE_PROTOCOL.Visor,
        },
        // { not includeded
        //   contractAddress: "0x9e8794486902d4aa0244ae7832c631f6f56f713f",
        //   protocol: FINANCE_PROTOCOL.Lixor,
        // },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "UNI/USDT",
    token1: "UNI",
    token2: "USDT",
    percent: "0.3%",
    address: "0x860f743395f7b988442b3bcd32248d726d71cd16",
    logo1: UNI_ICON,
    logo2: USDT_ICON,
    details: {
      token0Address: UNI,
      token1Address: USDT,
      contractAddress: "0x860f743395f7b988442b3bcd32248d726d71cd16",
      available: [
        {
          contractAddress: "0x860f743395f7b988442b3bcd32248d726d71cd16",
          protocol: FINANCE_PROTOCOL.Visor,
        },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "USDC/ETH",
    token1: "USDC",
    token2: "ETH",
    percent: "0.3%",
    address: "0x5902a5019547133d186e32578f0ce83cf842626d",
    logo1: USDC_ICON,
    logo2: ETH_ICON,
    details: {
      token0Address: USDC,
      token1Address: WETH,
      contractAddress: "0x5902a5019547133d186e32578f0ce83cf842626d",
      available: [
        {
          contractAddress: "0x5902a5019547133d186e32578f0ce83cf842626d",
          protocol: FINANCE_PROTOCOL.Visor,
        },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "USDC/USDT",
    token1: "USDC",
    token2: "USDT",
    percent: "1%",
    address: "0x80e0b252418637c175522158421f51f4428b7c63",
    logo1: USDC_ICON,
    logo2: USDT_ICON,
    details: {
      token0Address: USDC,
      token1Address: USDT,
      contractAddress: "0x80e0b252418637c175522158421f51f4428b7c63",
      available: [
        {
          contractAddress: "0x80e0b252418637c175522158421f51f4428b7c63",
          protocol: FINANCE_PROTOCOL.Visor,
        },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "WBTC/ETH",
    token1: "WBTC",
    token2: "ETH",
    percent: "0.3%",
    address: "0x2a7c48b62208410ba68144e34449dbe533c45bf7",
    logo1: WBTC_ICON,
    logo2: ETH_ICON,
    details: {
      token0Address: WBTC,
      token1Address: WETH,
      contractAddress: "0x2a7c48b62208410ba68144e34449dbe533c45bf7",
      available: [
        {
          contractAddress: "0x2a7c48b62208410ba68144e34449dbe533c45bf7",
          protocol: FINANCE_PROTOCOL.Lixor,
        },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "PILOT/ETH",
    token1: "PILOT",
    token2: "ETH",
    percent: "0.3%",
    address: "0x6086781ba226e6ebd53f453a9b10938dc74b84c8",
    logo1: UNIPILOT_ICON,
    logo2: ETH_ICON,
    details: {
      token0Address: PILOT,
      token1Address: WETH,
      contractAddress: "0x6086781ba226e6ebd53f453a9b10938dc74b84c8",
      available: [
        {
          contractAddress: "0x6086781ba226e6ebd53f453a9b10938dc74b84c8",
          protocol: FINANCE_PROTOCOL.Lixor,
        },
      ],
    },
  },

  // changes for Popsicle
  {
    id: uuidv4(),
    name: "UNI/WETH",
    token1: "UNI",
    token2: "WETH",
    percent: "0.3%",
    address: "0x9500e011538d26b4d9fac29578960c83402d3973",
    logo1: UNI_ICON,
    logo2: WETH_ICON,
    details: {
      token0Address: UNI,
      token1Address: WETH,
      contractAddress: "0x9500e011538d26b4d9fac29578960c83402d3973",
      available: [
        {
          contractAddress: "0x9500e011538d26b4d9fac29578960c83402d3973",
          protocol: FINANCE_PROTOCOL.Popsicle,
        },
      ],
    },
  },
  {
    id: uuidv4(),
    name: "WBTC/WETH",
    token1: "WBTC",
    token2: "WETH",
    percent: "0.3%",
    address: "0x726723ee191210918e813f64296d8b099e809f8d",
    logo1: WBTC_ICON,
    logo2: WETH_ICON,
    details: {
      token0Address: WBTC,
      token1Address: WETH,
      contractAddress: "0x726723ee191210918e813f64296d8b099e809f8d",
      available: [
        {
          contractAddress: "0x726723ee191210918e813f64296d8b099e809f8d",
          protocol: FINANCE_PROTOCOL.Popsicle,
        },
      ],
    },
  },
];

export default testData;
