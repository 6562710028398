import Web3 from "web3";
import { utils } from "ethers";

export const conciseAddress = (address: string) => {
  if (Web3.utils.isAddress(address)) {
    const addr = Web3.utils.toChecksumAddress(address);
    return `${addr.slice(0, 6)}...${addr.slice(addr.length - 3, addr.length)}`;
  }
  return "-";
};

export const toNonExponential = (value: number) => {
  // if value is not a number try to convert it to number
  if (typeof value !== "number") {
    value = parseFloat(value);

    // after convert, if value is not a number return empty string
    if (isNaN(value)) {
      return "";
    }
  }

  var sign;
  var e;

  // if value is negative, save "-" in sign variable and calculate the absolute value
  if (value < 0) {
    sign = "-";
    value = Math.abs(value);
  } else {
    sign = "";
  }

  // if value is between 0 and 1
  if (value < 1.0) {
    // get e value
    e = parseInt(value.toString().split("e-")[1]);

    // if value is exponential convert it to non exponential
    if (e) {
      value *= Math.pow(10, e - 1);
      value = parseFloat(
        "0." + new Array(e).join("0") + value.toString().substring(2)
      );
    }
  } else {
    // get e value
    e = parseInt(value.toString().split("e+")[1]);

    // if value is exponential convert it to non exponential
    if (e) {
      value /= Math.pow(10, e);
      value += Number(new Array(e + 1).join("0"));
    }
  }

  // if value has negative sign, add to it
  return sign + value;
};

export const ogTrunc = (val: number) => {
  if (!Number(val)) {
    return 0;
  }
  const _val = (val * 100 - Math.trunc(val) * 100) / 100;
  if (!_val) {
    return Math.trunc(val);
  }
  let decimal = 0;
  if (_val < 0.000001) {
    decimal = 7;
    return parseFloat(val.toString()).toFixed(7);
  } else if (_val < 0.00001) {
    decimal = 6;
  } else if (_val < 0.0001) {
    decimal = 5;
  } else if (_val < 0.001) {
    decimal = 4;
  } else if (_val < 0.01) {
    decimal = 3;
  } else if (_val < 1) {
    decimal = 2;
  } else {
    // console.log(Math.trunc(val).toString());
    return Math.trunc(val).toString();
  }
  const truncVal =
    _val.toString().match(new RegExp("^-?\\d+(?:.\\d{0," + decimal + "})?")) ??
    [][0];
  return (Math.trunc(val) + parseFloat(truncVal.toString())).toFixed(decimal);
};

export const trunc = (val: number) => {
  let _val = ogTrunc(val).toString();
  if (_val.includes("e") || _val.includes("E")) {
    const temp = toNonExponential(val);
    _val = ogTrunc(parseFloat(temp)).toString();
  }
  const _val2 = _val.split(".");
  if (_val2[0].length > 8) {
    const _val = _val2[0].slice(0, 4);
    const __val = _val2[0].slice(_val2.length - 4, -1);
    const joined = [_val, __val].join("..");
    return joined;
  }
  if (_val.includes(".")) {
    const splitedVal = _val.split(".");
    if (val < 0.0001) {
      return "<0.0001";
    }
    return [
      splitedVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      splitedVal[1],
    ].join(".");
  } else {
    return Number(_val).toLocaleString();
  }
};

export const toFixed = (amount: string, decimals: number) => {
  const regex = new RegExp("^-?\\d+(?:.\\d{0," + decimals + "})?");
  const _amount =
    amount.toString().split(".").length > 1
      ? amount.toString().match(regex) ?? [][0]
      : amount.toString();
  return _amount.toString();
};

export const parseAmount = (num: string, decimals: number) => {
  return utils.parseUnits(toFixed(num, decimals), decimals).toString();
};

export const formatAmount = (num: string, decimals: number) => {
  return utils.formatUnits(num, decimals);
};

export const sortObjectArray = (arr: any, key: string) => {
  const newArr = arr;
  newArr.sort((a: any, b: any) =>
    a[key].toLowerCase().localeCompare(b[key].toLowerCase())
  );
  return newArr;
};

export const toEth = (amount: string) => {
  return Web3.utils.fromWei(amount, "ether");
};

export const toWei = (amount: string) => {
  return Web3.utils.toWei(amount, "ether");
};

export const toChecksumAddress = (address: string) => {
  return Web3.utils.toChecksumAddress(address);
};

export const exponentialToDecimal = (exponential: string) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text: string) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)}${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

export const truncSymbol = (symbol: string, length: number = 5) => {
  return symbol.length > length ? `${symbol.substring(0, length)}...` : symbol;
};
