import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  button: {
    background: "rgb(255, 165, 0, 0.3)",
    color: "#ffa500",
    textTransform: "none",
    padding: "8px 0px",
    fontSize: "16px",
    "&:hover": {
      background: "rgb(255, 165, 0, 0.4)",
    },
  },
}));

export default useStyles;
