import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  //   disableHover: {
  //     justifyContent: "space-between",
  //     "&:hover": {
  //       backgroundColor: "",
  //     },
  //   },
}));

export default useStyles;
